// REACT COMPONENTS

import React, { useEffect } from 'react'

// HOOKS

import { useGoTop } from '../../utils/hooks'

// FONTAWESOME

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function GoTop() {
  const { goTop, setGoTop, preventGlitch } = useGoTop()

  useEffect(() => {
    preventGlitch.current = true
    const glitch = setTimeout(() => {
      preventGlitch.current = false
    }, 1000)
    return () => clearTimeout(glitch)
  }, [goTop])

  return (
    <a
      href="#goTop"
      className={`shadow-sm position-fixed gotop-button animate__animated animate__faster bg-secondary-dgt ${
        goTop ? 'animate__slideInRight' : 'animate__slideOutRight'
      }`}
      onClick={() => setGoTop(false)}
    >
      <FontAwesomeIcon
        size="2x"
        icon="chevron-up"
        className={`svg-primary text-center`}
      />
    </a>
  )
}

export default GoTop
