import React, { useState } from 'react'
import Reaptcha from 'reaptcha'
import Button from 'react-bootstrap/Button'

function Recaptcha() {
  const [verified, setVerified] = useState(false)
  const [robot, updateRobot] = useState()

  const onVerify = (recaptchaResponse) => {
    setVerified(true)
  }

  return (
    <div className="d-flex flex-column align-items-center">
      <Reaptcha
        sitekey="6Lf73CkhAAAAALRXkH6WQs26Emx_G9VTqkGBHA-5"
        onVerify={onVerify}
        className={robot === false ? 'focus-robot' : ''}
      />
      <div onClick={() => updateRobot(verified)}>
        <Button
          type="submit"
          value="Send"
          disabled={!verified}
          className={`mt-2 dgt-btn dgt-cta text-colored-primary ${
            !verified && 'opacity-25'
          }`}
        >
          J'envoie ma demande !
        </Button>
      </div>
    </div>
  )
}

export default Recaptcha
