// REACT COMPONENTS

import React, { useEffect, useState } from 'react'

// REACT ROUTER

import { Link, useLocation, createSearchParams } from 'react-router-dom'

// HOOKS

import { useTheme, useAnimAppear } from '../../../utils/hooks'

// REACT BOOTSTRAP

import { Row, Col, ListGroup } from 'react-bootstrap'

// FONTAWESOME

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Offer({
  id,
  cover,
  title,
  subtitle,
  price,
  recurrence,
  discount,
  priceDiscount,
  target,
  servicesIncluded,
  index
}) {
  let location = useLocation()
  const { elemsAnim, animTarget } = useAnimAppear()
  const [positionArrToAnim, setPositionArrToAnim] = useState()

  const valuesServices = Object.values(servicesIncluded)
  const { theme } = useTheme()

  useEffect(() => {
    if (animTarget.length > 0)
      setPositionArrToAnim(animTarget.indexOf('offersHomeAnim'))
  }, [animTarget])

  return (
    <>
      <Row
        id={id}
        className={`offer mt-0 mt-4 mt-sm-5 pt-3 box-shadow-none rounded-3  ${
          elemsAnim &&
          elemsAnim.length > 0 &&
          elemsAnim[positionArrToAnim] &&
          typeof elemsAnim[positionArrToAnim][index] === 'string' &&
          location.pathname === '/'
            ? elemsAnim[positionArrToAnim][index]
            : location.pathname === '/offres'
            ? 'animate__animated animate__zoomInUp'
            : 'opacity-0'
        } ${
          id === 'offer-1'
            ? 'offer-silver'
            : id === 'offer-2'
            ? 'offer-gold mt-5'
            : 'offer-premium'
        } ${
          theme === 'dark' && id !== 'offer-2'
            ? 'shadow-dgt-light'
            : theme === 'light' && id !== 'offer-2'
            ? 'border border-opacity-50 shadow border-small-none'
            : ''
        } 
        ${theme === 'light' ? 'bg-white' : ''}`}
      >
        <Col className={`px-3 ${id !== 'offer2' ? '' : ''}`}>
          {id === 'offer2' && discount ? (
            <div className="offer-icon position-absolute start-100 translate-middle">
              <FontAwesomeIcon icon="percent" size="2x" className="svg-light" />
            </div>
          ) : null}

          <header className={`text-center mt-3 ${id === 'offer2' ? '' : ''}`}>
            {title === 'Silver' ? (
              <FontAwesomeIcon
                icon="helicopter"
                size="3x"
                transform={{ rotate: -5 }}
                className="svg-secondary"
              />
            ) : title === 'Gold' ? (
              <FontAwesomeIcon
                icon="plane"
                size="3x"
                beat
                transform={{ rotate: -30 }}
                className="plane-offer svg-secondary"
              />
            ) : (
              <FontAwesomeIcon
                icon="shuttle-space"
                size="3x"
                transform={{ rotate: -90 }}
                className="svg-secondary"
              />
            )}

            <h2 className="huge-text-3 mt-4 mb-0 text-center text-colored-secondary-letter">
              {title}
            </h2>
            <p className="fw-light fs-5 my-2">{subtitle}</p>
            {discount ? (
              <div>
                {priceDiscount && (
                  <h4 className="d-block mb-0 pb-0 text-colored-primary">
                    <del>
                      {priceDiscount}
                      {recurrence && { recurrence }}
                    </del>
                  </h4>
                )}

                <h2 className="pt-0 mt-2 text-colored-primary">
                  {price} {recurrence}
                </h2>
              </div>
            ) : (
              <h2
                className={`fs-2 my-3 ${
                  theme === 'light'
                    ? 'text-colored-primary'
                    : 'text-colored-secondary'
                }`}
              >
                {price}
                {recurrence}
              </h2>
            )}
            <p
              className="my-0"
              dangerouslySetInnerHTML={{ __html: target }}
            ></p>
          </header>
          <Row className="hstack justify-content-center">
            <Col xs={3}>
              <hr
                className={`opacity-50 my-3 ${
                  theme === 'dark' ? 'neutral' : ''
                }`}
              />
            </Col>
          </Row>
          <ListGroup
            id={`collapse-${id}`}
            variant="flush"
            className={`bg-transparent ${id === 'offer2' ? 'px-3' : ''}`}
          >
            {valuesServices.map((item, number) => (
              <ListGroup.Item
                key={`list-${number}`}
                className={`text-center bg-transparent border border-0 py-2 ${
                  theme === 'light' ? 'text-white' : 'text-white'
                }`}
              >
                <FontAwesomeIcon
                  icon="hand-point-right"
                  className="me-3 svg-secondary"
                />
                <span
                  className={`${
                    id === 'offer1' && number > 7
                      ? 'opacity-25'
                      : id === 'offer2' && number > 10
                      ? 'opacity-25'
                      : ''
                  }`}
                  dangerouslySetInnerHTML={{ __html: item }}
                ></span>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <div className="d-flex justify-content-center pb-4">
            <Link
              to={{
                pathname: '/contact',
                search: `?${createSearchParams({
                  _offerName: title,
                  _offerPosition: index
                })}`
              }}
              className={`dgt-btn mt-3 dgt-cta`}
            >
              Je prends contact
            </Link>
          </div>
        </Col>
      </Row>
      {index === 0 && (
        <Row className="hstack justify-content-center d-sm-none">
          <Col xs={3}>
            <hr
              className={`opacity-50 mt-5 mb-4 ${
                theme === 'dark' ? 'neutral' : ''
              }`}
            />
          </Col>
        </Row>
      )}
    </>
  )
}
export default Offer
