// REACT COMPONENTS

import React from 'react'

// HOOKS

import { useTheme } from '../../../utils/hooks'

// REACT BOOTSTRAP

import { Row, Col } from 'react-bootstrap'

// DATAS

import { includedAllOffers } from '../../../assets/datas/offers'

// FONTAWESOME

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function SharedOffers() {
  const { theme } = useTheme()
  return (
    <>
      <p className="title-anim text-center fs-3 fw-light mb-5 animate__animated animate__flipInX">
        Inclus dans <span>toutes nos offres</span>
      </p>
      <Row xs={1} sm={2} md={3} lg={4} xl={5} xxl={6} className="g-4 mt-0">
        {includedAllOffers.map(({ description, icon }, index) => (
          <Col
            key={`shared-services-${index}`}
            className="d-flex align-items-center flex-column justify-content-start animate__animated animate__fadeInUp animate__delay-1s"
          >
            <FontAwesomeIcon
              icon={icon}
              size="3x"
              className={`d-block p-4 rounded-4 ${
                theme === 'light'
                  ? 'shadow svg-primary bg-white'
                  : 'shadow-dgt-light svg-secondary bg-transparent'
              }`}
            />
            <section className="mt-4 text-center">
              <FontAwesomeIcon
                size="1x"
                icon="hand-point-down"
                className="svg-secondary display-block mb-2"
              />
              <p>{description}</p>
            </section>
          </Col>
        ))}
      </Row>
      <p className="mt-5 mb-lg-5 mb-0 pb-lg-5 title-anim text-center fs-3 fw-light">
        Choisissez ci-dessous l'offre <span>la plus adaptée</span> à votre
        besoin
      </p>
    </>
  )
}
export default SharedOffers
