// REACT

import React from 'react'

// DATAS

import { socials } from '../../assets/datas/socialNetworks'

function SocialNetWorks() {
  return (
    <>
      {socials.map((elem, index) =>
        elem.display ? (
          <li key={index}>
            <a
              className="d-block"
              href={elem.url}
              target="_blank"
              rel="noreferrer"
            >
              <i
                className={`bi bi-${elem.name} icon-sm ms-3`}
                aria-label={elem.name}
              ></i>
            </a>
          </li>
        ) : null
      )}
    </>
  )
}

export default SocialNetWorks
