import webdesign from '../../img/skills/webdesign.svg'
import print from '../../img/skills/print.svg'
import siteBuild from '../../img/skills/site-build.svg'
import update from '../../img/skills/update.svg'
import development from '../../img/skills/development.svg'

export const skills = [
  {
    id: 'creation-site',
    name: 'Création de site de A à Z',
    picturePath: siteBuild,
    description:
      'Parfois, on aime créer une nouvelle recette ou une nouvelle playlist mais quand il s\'agit de lancer son entreprise dans le monde du digital la peur est souvent de mise 😰 ! Choix des typos et des couleurs, boutons ronds ou carrés, article à droite ou à gauche, 1, 3 ou 18 pages ? Ne bougez plus, <span class="fw-semibold">on s\'occupe de tout pour vous créer un site efficace qui correspondra à votre image !</span>'
  },
  {
    id: 'webdesign',
    name: 'Webdesign',
    picturePath: webdesign,
    description:
      'Votre site web est un de vos <span class="fw-semibold">meilleurs atouts</span> pour faire parler de vous sur internet et un <span class="fw-semibold">outil marketing primordial</span>. Deegity met un point d\'honneur à choisir ou bien vous concevoir un <span class="fw-semibold">webdesign de qualité</span>, alliant à la fois <span class="fw-semibold">esthétisme, et ergonomie</span> afin de <span class="fw-semibold">représenter</span> votre marque de la meilleure manière possible.'
  },
  {
    id: 'mise-a-jour',
    name: 'Mise à jour',
    picturePath: update,
    description:
      'Si la mise à jour de votre téléphone vous fait peur, nous pouvons imaginer votre détresse au moment de mettre à jour votre site sous Wordpress ou quand vous songez à la refonte complète de votre site web. Plus de panique, nous sommes là pour vous <span class="fw-semibold">guider et vous aider à accomplir ces étapes nécessaires à la transormation digitale de votre entreprise.</span>'
  },
  {
    id: 'developpement',
    name: 'Développement',
    picturePath: development,
    description:
      'Besoin d\'une <span class="fw-semibold">fonctionnalité particulière, d\'animer un élément visuel sur votre site, de réparer un éventuel bug ou simplement de créer une nouvelle page sur votre site ?</span> Vous êtes au bon endroit !'
  },
  {
    id: 'print',
    name: 'Print',
    picturePath: print,
    description:
      "Parce que la vie ne se résume pas qu'à un écran (si si c'est vrai 🤓), vous pourriez aussi avoir besoin <span class=\"fw-semibold\">d'une carte de visite, d'une plaquette commerciale, d'une double page ou bien encore d'un panneau publicitaire</span> dans le cadre de votre plan de communication. Deegity sait aussi répondre à ces besoins pour vous aider à <span class=\"fw-semibold\">construire l'identité visuelle de votre entreprise et accroître sa notoriété.</span>"
  }
]
