// REACT COMPONENTS

import React, { useEffect, useState, useRef } from 'react'

// META TAGS

import { Helmet } from 'react-helmet-async'

// REACT ROUTER

import { useLocation } from 'react-router-dom'

// HOOKS

import { useAnimAppear, useTheme } from '../../utils/hooks'

// BOOTSTRAP COMPONENTS

import { Container, Row, Col, Image } from 'react-bootstrap'

//FONTAWESOME

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// MY COMPONENTS

import Skills from '../../components/skills'

// IMAGES

import headerServices3 from '../../assets/img/homepage/presentation/overlay-computer-coffee.svg'
import servicesThumb from '../../assets/img/skills/services-thumb.jpg'

function ServicesPage() {
  const { theme } = useTheme()
  let location = useLocation()

  const { setParamsAnim, setCountAnimation } = useAnimAppear()

  useEffect(() => {
    const windowHeight = window.innerHeight
    const header = document.getElementsByClassName('container-header')[0]
    const headerHeight = header.offsetHeight
    const introServices = document.getElementById('intro-services')
    introServices.style.height = windowHeight - headerHeight + 'px'

    const skillsElementsPage = document.querySelectorAll('[id^="skill"]')

    setParamsAnim([
      [
        [
          skillsElementsPage,
          'animate__animated animate__fadeInUp',
          700,
          'skillsPageServicesAnim'
        ]
      ],
      location.pathname
    ])

    setCountAnimation(true)
    window.requestAnimationFrame(forceUpdate)
    window.scrollTo(0, 0)
  }, [])

  const [value, setValue] = useState(0) // integer state

  const lastTimeAnim = useRef(null)
  const counterAnim = useRef(0)

  const forceUpdate = (time) => {
    // update state to force render
    // An function that increment the previous state like here
    // is better than directly setting `value + 1`

    if (lastTimeAnim.current !== null) {
      const delta = time - lastTimeAnim.current
      counterAnim.current += delta * 5
      if (counterAnim.current > 10000) {
        setValue((value) => value + 1)
        counterAnim.current = 0
      }
    }
    lastTimeAnim.current = time
    window.requestAnimationFrame(forceUpdate)
  }

  useEffect(() => {
    return () => window.cancelAnimationFrame(forceUpdate)
  }, [value])

  return (
    <>
      <Helmet>
        <title>Les services</title>
        <meta property="og:title" content={`Les services`} />
        <meta
          property="og:description"
          content={`Découvrez tous les services proposés par Deegity`}
        />
        <meta
          name="description"
          content={`Découvrez tous les services proposés par Deegity`}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta
          property="og:image:secure_url"
          content={`https://www.deegity.com${servicesThumb}`}
        />
        <meta property="og:url" content={`https://www.deegity.com/services`} />
      </Helmet>
      <Container
        fluid
        id="intro-services"
        className={`overflow-hidden d-flex align-items-lg-center ${
          theme === 'light' ? 'bg-theme-light' : 'bg-pattern-dark-mode'
        }`}
      >
        <Container>
          <Row>
            <Col lg={6} className="d-none d-lg-block">
              <Image
                fluid
                src={headerServices3}
                className={`py-5 animate__animated animate__fadeInLeft animate__delay-1s`}
              />
            </Col>
            <Col
              xs={12}
              lg={{ span: 5, offset: 1 }}
              className="pt-5 pt-lg-0 mt-lg-0 d-flex align-items-lg-center animate__animated animate__fadeInRight animate__delay-1s"
            >
              <section>
                <h1 className="title-anim huge-text-3 mt-0 mb-5 animate__animated animate__flipInX animate__delay-2s">
                  Les <span>services</span>
                </h1>
                <p>
                  Quel que soit votre projet et le service dont vous avez
                  besoin, nous mettons en oeuvre tout notre savoir-faire et
                  notre passion afin de vous apporter la meilleure réponse
                  digitale possible.
                </p>
                <ul className="mt-2 list-dgt list-unstyled">
                  <li>
                    <a href="#skill-creation-site">
                      <FontAwesomeIcon
                        size="1x"
                        icon="hand-point-right"
                        className="svg-secondary display-block"
                      />
                      <span>Création de site de A à Z</span>
                    </a>
                  </li>
                  <li>
                    <a href="#skill-webdesign">
                      <FontAwesomeIcon
                        size="1x"
                        icon="hand-point-right"
                        className="svg-secondary display-block"
                      />
                      <span>Webdesign</span>
                    </a>
                  </li>
                  <li>
                    <a href="#skill-mise-a-jour">
                      <FontAwesomeIcon
                        size="1x"
                        icon="hand-point-right"
                        className="svg-secondary display-block"
                      />
                      <span>Mise à jour</span>
                    </a>
                  </li>
                  <li>
                    <a href="#skill-developpement">
                      <FontAwesomeIcon
                        size="1x"
                        icon="hand-point-right"
                        className="svg-secondary display-block"
                      />
                      <span>Développement</span>
                    </a>
                  </li>
                  <li>
                    <a href="#skill-print">
                      <FontAwesomeIcon
                        size="1x"
                        icon="hand-point-right"
                        className="svg-secondary display-block"
                      />
                      <span>Print</span>
                    </a>
                  </li>
                </ul>
              </section>
            </Col>
          </Row>

          <Row>
            <a className="arrow-anim" href="#skill-creation-site">
              <span className="arrow-left"></span>
              <span className="arrow-right"></span>
            </a>
          </Row>
        </Container>
      </Container>

      <Container
        fluid
        className={`py-5 ${
          theme === 'light' ? 'bg-lightGray' : 'bg-pattern-dark-mode'
        } `}
      >
        <Container>
          <Skills />
        </Container>
      </Container>
    </>
  )
}
export default ServicesPage
