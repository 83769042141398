// REACT COMPONENTS

import React, { useState, createContext, useEffect } from 'react'

// DATAS

import { realisationsList } from '../../assets/datas/realisations'

// REACT ROUTER

import { useLocation } from 'react-router-dom'

// DAY / NIGHT  MODE CONTEXT

export const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const localThemeSaved = localStorage.getItem('theme')
  let hr = new Date().getHours()
  const themeFunc = () => {
    if (hr > 7 && hr < 18) return 'light'
    else return 'dark'
  }
  window
    .matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', (e) => {
      e.matches ? setTheme('dark') : setTheme('light')
    })
  const [theme, setTheme] = useState(themeFunc())
  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light')
  }
  const body = document.body.classList
  const addBodyClass = (className) => body.add(className)
  const removeBodyClass = (className) => body.remove(className)

  useEffect(() => {
    setTheme(themeFunc())
    if (body.length === 0) {
      addBodyClass(`deegity-theme-${theme}`)
    }
  }, [])

  useEffect(() => {
    const localThemeSet = localStorage.setItem('theme', JSON.stringify(theme))
    if (body.length > 0) {
      const bodyClassNow = body.value
      removeBodyClass(bodyClassNow)
      addBodyClass(`deegity-theme-${theme}`)
    }
  }, [theme])

  return (
    <ThemeContext.Provider value={{ theme, setTheme, toggleTheme, themeFunc }}>
      {children}
    </ThemeContext.Provider>
  )
}

///////////////////////////////////////////////////////////////////////////////

//  REALISATIONS CONTEXT

export const RealisationsContext = createContext()

export const RealisationsProvider = ({ children }) => {
  // CREATE ARRAYS OF EVERY TYPE OF FILTERS IN REALISATIONS

  const authors = realisationsList.reduce(
    (acc, realisation) =>
      acc.includes(realisation.author) ? acc : acc.concat(realisation.author),
    []
  )
  const years = realisationsList.reduce(
    (acc, realisation) =>
      acc.includes(realisation.year) ? acc : acc.concat(realisation.year),
    []
  )
  years.sort()
  const companies = realisationsList.reduce(
    (acc, realisation) =>
      acc.includes(realisation.company) ? acc : acc.concat(realisation.company),
    []
  )
  const categories = realisationsList.reduce(
    (acc, realisation) =>
      acc.includes(realisation.category)
        ? acc
        : acc.concat(realisation.category),
    []
  )

  ////////////////////////////////////////////////////

  // FILTERS STATES

  const [filteredCategories, setCategories] = useState(categories)
  const [filteredYears, setYears] = useState(years)
  const [filteredAuthors, setAuthors] = useState(authors)
  const [filteredCompanies, setCompanies] = useState(companies)
  const [activeCat, setActiveCat] = useState('')
  const [activeYear, setActiveYear] = useState('')
  const [activeCompany, setActiveCompany] = useState('')
  const [activeAuthor, setActiveAuthor] = useState('')
  const [checkedCategories, setCheckedCategories] = useState(
    new Array(categories.length).fill(false)
  )
  const [checkedCompanies, setCheckedCompanies] = useState(
    new Array(companies.length).fill(false)
  )
  const [checkedAuthors, setCheckedAuthors] = useState(
    new Array(authors.length).fill(false)
  )
  const [checkedYears, setCheckedYears] = useState(
    new Array(years.length).fill(false)
  )
  const [disabledCategories, setDisabledCategories] = useState(
    new Array(categories.length).fill(false)
  )
  const [disabledCompanies, setDisabledCompanies] = useState(
    new Array(companies.length).fill(false)
  )
  const [disabledAuthors, setDisabledAuthors] = useState(
    new Array(authors.length).fill(false)
  )
  const [disabledYears, setDisabledYears] = useState(
    new Array(years.length).fill(false)
  )

  const [realisations, updateRealisationList] = useState([])
  const [realisationsFiltered, updateRealisationsFiltered] = useState([])
  const [selectedFilters, updateSelectedFilters] = useState([])
  const [filtering, setFiltering] = useState(false)
  const [otherCat, setOtherCat] = useState(false)

  const [addOrRemove, setAddOrRemove] = useState()
  const [lastCategSelected, setLastCategSelected] = useState('')
  const [filterSelected, setFilterSelected] = useState('')
  const [nextFiltersCateg, setNextFiltersCateg] = useState([])

  ///////////////////////////////////////////////////////

  return (
    <RealisationsContext.Provider
      value={{
        authors,
        years,
        companies,
        categories,
        filteredAuthors,
        filteredCategories,
        filteredCompanies,
        filteredYears,
        activeCat,
        activeYear,
        activeCompany,
        activeAuthor,
        checkedYears,
        checkedAuthors,
        checkedCategories,
        checkedCompanies,
        realisations,
        selectedFilters,
        realisationsFiltered,
        disabledCompanies,
        disabledYears,
        disabledAuthors,
        disabledCategories,
        filtering,
        otherCat,
        addOrRemove,
        lastCategSelected,
        filterSelected,
        nextFiltersCateg,
        setActiveAuthor,
        setActiveCat,
        setActiveCompany,
        setActiveYear,
        setAuthors,
        setCategories,
        setYears,
        setCompanies,
        setCheckedAuthors,
        setCheckedCategories,
        setCheckedCompanies,
        setCheckedYears,
        updateRealisationList,
        updateSelectedFilters,
        updateRealisationsFiltered,
        setDisabledCompanies,
        setDisabledYears,
        setDisabledAuthors,
        setDisabledCategories,
        setFiltering,
        setOtherCat,
        setAddOrRemove,
        setLastCategSelected,
        setFilterSelected,
        setNextFiltersCateg
      }}
    >
      {children}
    </RealisationsContext.Provider>
  )
}

/////////// ELEMENTS ANIMATION WHEN USER REACHED THEM ONSCROLL

export const AnimationsContext = createContext()

export const AnimationsProvider = ({ children }) => {
  let location = useLocation()
  const [countAnimation, setCountAnimation] = useState(true)
  const [elemsToAnim, setElemsToAnim] = useState(true)

  // tous mes id d'elem sur lesquels je veux avoir une anim ===> 1 tableau de tableaux de string
  const [arr, setArr] = useState([])

  // tous mes noms d'anim ====> 1 tableau de tableaux de string
  const [animName, setAnimName] = useState([])

  //  tous mes seuils déclencheurs 1 tableau de tableaux de number
  const [threshold, setThreshold] = useState([])

  // tableau de tableaux d'elems à animer avec la valeur false ou le string de l'anim
  const [elemsAnim, setElemsAnim] = useState([])

  // tous les params
  const [paramsAnim, setParamsAnim] = useState([])

  // les targets d'animations
  const [animTarget, setAnimTarget] = useState([])

  // les targets d'animations
  const [locationTarget, setLocationTarget] = useState()

  useEffect(() => {
    if (paramsAnim.length > 0) {
      // je stocke les valeurs de toutes les anims dans des variables et je le fais une seule fois (countanimation = false à la fin)
      if (countAnimation) {
        let preArr = []
        let preAnimName = []
        let preThreshold = []
        let preAnimTarget = []

        const justParams = paramsAnim[0]

        if (justParams) {
          for (let i = 0; i < justParams.length; i++) {
            preArr = [...preArr, justParams[i][0]]
            preAnimName = [...preAnimName, justParams[i][1]]
            preThreshold = [...preThreshold, justParams[i][2]]
            preAnimTarget = [...preAnimTarget, justParams[i][3]]
          }
        } else return

        setArr(preArr)
        setAnimName(preAnimName)
        setThreshold(preThreshold)
        setAnimTarget(preAnimTarget)
        setLocationTarget(paramsAnim[1])

        // setCountAnimation(false)

        // j'itère sur mon tableau de tableaux de string (elements) pour former une ref elemsanim qui contient une valeur false pour chaque element au debut et je le fais une fois (stop = true)

        if (elemsToAnim) {
          let preElemsAnim = []
          for (let a = 0; a < preArr.length; a++) {
            preElemsAnim = [
              ...preElemsAnim,
              new Array(preArr[a].length).fill(false)
            ]
          }
          setElemsAnim(preElemsAnim)
        }
      }
      setCountAnimation(false)
    } else return

    window.addEventListener('scroll', isReached)
    return () => window.removeEventListener('scroll', isReached)
  }, [paramsAnim, arr, threshold, elemsAnim, animName])

  const isReached = () => {
    // si const position < 0, alors je réécris mon tableau elemsAnim avec les valeurs
    // exemple avec 3 blocs composés de plusieurs elems à animer :
    // [ [ 'anim' , 'anim', false , false ] , [ 'anim' , false , false ] , [ false ] ]

    if (arr[0].length === 0 || !location.pathname.startsWith(locationTarget)) {
      return
    } else {
      let globalArr = new Array(arr.length).fill([])

      // S'il y a 3 bloc à animer alors j = 2
      for (let j = 0; j < arr.length; j++) {
        // S'il y a 5 elem à animer dans un bloc alors k = 4
        for (let k = 0; k < arr[j].length; k++) {
          const position =
            document.getElementById(arr[j][k].id).getBoundingClientRect().top -
            threshold[j]

          if (position && position < 0) {
            const newArr = elemsAnim[j].map((elem, index) => {
              if (index === k || elem === animName[j]) {
                return (elem = animName[j])
              } else return (elem = false)
            })

            // Ci-dessous j'essaye d'update proprement elemsAnim avec le set State mais ça provoque une erreur

            // const replace = elemsAnim.map((el, index) => {
            //   if (j === index) {
            //     return (el = newArr)
            //   } else return el
            // })

            // setElemsAnim(replace)

            elemsAnim[j] = newArr
          }
        }
      }
    }
  }

  return (
    <AnimationsContext.Provider
      value={{
        arr,
        setArr,
        animName,
        setAnimName,
        threshold,
        setThreshold,
        elemsAnim,
        setElemsAnim,
        paramsAnim,
        setParamsAnim,
        animTarget,
        setAnimTarget,
        countAnimation,
        setCountAnimation
      }}
    >
      {children}
    </AnimationsContext.Provider>
  )
}
