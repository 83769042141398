// OTHER QUESTION POSSIBLE
// Avez-vous déjà le contenu des pages suivantes :  Mentions légales, Protection des données, Conditions générales de ventes ?

export const questionTitle = [
  {
    step: 1,
    title: 'Vous avez un projet, une envie ?',
    titleShort: 'Coordonnées',
    subtitle:
      'Remplissez le formulaire ci-dessous, nous vous contacterons très prochainement !',
    icon: 'user'
  },
  {
    step: 2,
    title: 'Site / hébergement / données',
    titleShort: 'Site',
    subtitle: '',
    icon: 'display'
  },
  {
    step: 3,
    title: 'Objectif',
    titleShort: 'Objectif',
    subtitle: '',
    icon: 'bullseye'
  },
  {
    step: 4,
    title: 'Fonctionnalités / arborescence',
    titleShort: 'Fonctionnalités',
    subtitle: '',
    icon: 'folder-tree'
  },
  {
    step: 5,
    title: 'Utilisateurs',
    titleShort: 'Utilisateurs',
    subtitle: '',
    icon: 'users'
  },
  {
    step: 6,
    title: 'Dernières questions',
    titleShort: 'Dernières questions',
    subtitle: '',
    icon: 'flag-checkered'
  }
]

export const questionFormList = [
  {
    step: 1,
    content: 'Vos coordonnées',
    type: 'single',
    controlId: 'contactDetails',
    required: false,
    xscol: 12,
    name: 'user_details'
  },
  {
    step: 1,
    content: 'Nom *',
    type: 'text',
    controlId: 'inputLastName',
    placeholder: 'Leclerc',
    msgInvalid: "Merci d'écrire votre nom de famille.",
    conditionalDisplay: false,
    required: true,
    xscol: 12,
    mdcol: 6,
    display: true,
    name: 'user_name'
  },
  {
    step: 1,
    content: 'Prénom *',
    type: 'text',
    controlId: 'inputFirstName',
    placeholder: 'Marie',
    msgInvalid: "Merci d'écrire votre prénom.",
    conditionalDisplay: false,
    required: true,
    xscol: 12,
    mdcol: 6,
    display: true,
    name: 'user_first_name'
  },
  {
    step: 1,
    content: 'Email *',
    type: 'email',
    controlId: 'inputEmail',
    placeholder: 'Votre adresse email',
    msgInvalid: 'Merci de remplir ce champ avec une adresse email valide.',
    conditionalDisplay: false,
    required: true,
    xscol: 12,
    mdcol: 6,
    display: true,
    name: 'user_email'
  },
  {
    step: 1,
    content: 'Téléphone',
    type: 'tel',
    controlId: 'inputPhone',
    placeholder: '+33 6 01 02 03 04',
    msgInvalid: '',
    conditionalDisplay: false,
    required: false,
    xscol: 12,
    mdcol: 6,
    display: true,
    name: 'user_tel'
  },
  {
    step: 1,
    content: 'Numéro de voie',
    type: 'text',
    controlId: 'inputNumberRoad',
    placeholder: '42',
    msgInvalid: "Merci d'écrire votre numéro de voie",
    conditionalDisplay: false,
    required: false,
    xscol: 12,
    mdcol: 4,
    display: false,
    name: 'user_road_number'
  },
  {
    step: 1,
    content: 'Nom de la voie',
    type: 'text',
    controlId: 'inputNameRoad',
    placeholder: 'boulevard Voltaire',
    msgInvalid: "Merci d'écrire le nom de la voie",
    conditionalDisplay: false,
    required: false,
    xscol: 12,
    mdcol: 8,
    display: false,
    name: 'user_road_name'
  },
  {
    step: 1,
    content: 'Code postal',
    type: 'number',
    controlId: 'inputZipCode',
    placeholder: '01000',
    conditionalDisplay: false,
    required: false,
    xscol: 12,
    mdcol: 4,
    display: false,
    name: 'user_zipcode'
  },
  {
    step: 1,
    content: 'Ville',
    type: 'text',
    controlId: 'inputCityCode',
    placeholder: 'Nice',
    conditionalDisplay: false,
    required: false,
    xscol: 12,
    mdcol: 8,
    display: false,
    name: 'user_city'
  },
  {
    step: 1,
    content: 'Pays',
    type: 'text',
    controlId: 'inputCountryName',
    placeholder: 'France',
    msgInvalid: '',
    conditionalDisplay: false,
    required: false,
    xscol: 12,
    mdcol: 4,
    display: false,
    name: 'user_country'
  },
  {
    step: 1,
    content: "Nom de l'entreprise / association",
    type: 'text',
    controlId: 'inputCompanyName',
    placeholder: 'Le nom de votre entreprise',
    msgInvalid: '',
    conditionalDisplay: false,
    required: false,
    xscol: 12,
    display: true,
    name: 'user_company'
  },
  {
    step: 1,
    content: 'Cochez le(s) service(s) dont vous avez besoin',
    type: 'single',
    controlId: 'whichServices',
    required: false,
    xscol: 12,
    name: 'user_services'
  },
  {
    step: 1,
    type: 'checkbox',
    checked: 0,
    content: 'Web Design',
    controlId: 'inputServiceWeb',
    typeContent: 'service',
    msgInvalid: '',
    xscol: 12,
    mdcol: 4,
    display: true,
    icon: ['fas', 'palette'],
    name: 'user_service_webdesign'
  },
  {
    step: 1,
    type: 'checkbox',
    checked: 1,
    content: 'Développement',
    controlId: 'inputServiceDev',
    typeContent: 'service',
    msgInvalid: '',
    xscol: 12,
    mdcol: 4,
    display: true,
    icon: ['fas', 'code'],
    name: 'user_service_dev'
  },
  {
    step: 1,
    type: 'checkbox',
    checked: 2,
    content: 'Print',
    controlId: 'inputServicePrint',
    typeContent: 'service',
    msgInvalid: '',
    xscol: 12,
    mdcol: 4,
    display: true,
    icon: ['fas', 'newspaper'],
    name: 'user_service_print'
  },
  {
    step: 1,
    type: 'checkbox',
    checked: 3,
    content: 'Création site',
    controlId: 'inputServiceCreateSite',
    typeContent: 'service',
    msgInvalid: '',
    xscol: 12,
    mdcol: 4,
    display: true,
    icon: ['fas', 'display'],
    name: 'user_service_create_site'
  },
  {
    step: 1,
    type: 'checkbox',
    checked: 4,
    content: 'Une mise à jour',
    controlId: 'inputServiceUpdate',
    typeContent: 'service',
    msgInvalid: '',
    xscol: 12,
    mdcol: 4,
    display: true,
    icon: ['fas', 'person-digging'],
    name: 'user_service_update'
  },
  {
    step: 1,
    type: 'checkbox',
    checked: 5,
    content: 'Autre',
    controlId: 'inputServiceOther',
    typeContent: 'service',
    msgInvalid: '',
    xscol: 12,
    mdcol: 4,
    display: true,
    icon: ['fas', 'fingerprint'],
    name: 'user_service_other'
  },
  {
    step: 1,
    content: 'Précisez votre demande',
    type: 'text',
    controlId: 'inputOtherDemand',
    placeholder: 'Précisez votre demande',
    conditionalDisplay: true,
    required: false,
    relatedTo: 'otherDemand',
    xscol: 12,
    display: true,
    name: 'user_service_other_input'
  },
  {
    step: 1,
    content: "Cochez l'offre voulue",
    type: 'single',
    controlId: 'whichOffer',
    required: false,
    xscol: 12,
    name: 'user_offer'
  },
  {
    step: 1,
    content: 'Deegity Start',
    type: 'checkbox',
    checked: 0,
    controlId: 'inputOffer1',
    typeContent: 'offer',
    required: false,
    xscol: 12,
    mdcol: 4,
    display: true,
    icon: ['fas', 'helicopter'],
    name: 'user_offer_1',
    transform: -5
  },
  {
    step: 1,
    content: 'Deegity Gold',
    type: 'checkbox',
    checked: 1,
    controlId: 'inputOffer2',
    typeContent: 'offer',
    conditionalDisplay: true,
    required: false,
    xscol: 12,
    mdcol: 4,
    display: true,
    icon: ['fas', 'plane'],
    name: 'user_offer_2',
    transform: -30
  },
  {
    step: 1,
    content: 'Deegity Premium',
    type: 'checkbox',
    checked: 2,
    controlId: 'inputOffer3',
    typeContent: 'offer',
    conditionalDisplay: true,
    required: false,
    xscol: 12,
    mdcol: 4,
    display: true,
    icon: ['fas', 'shuttle-space'],
    name: 'user_offer_3',
    transform: -90
  },
  {
    step: 2,
    content: 'Y a t-il un site existant ?',
    type: 'radio',
    controlId: 'inputSiteExist',
    placeholder: '',
    name: 'user_site_exist',
    inputsRadio: [
      {
        label: 'Non',
        // group: 'site',
        type: 'radio',
        id: 'inputSiteExistNo',
        function: 'updateSiteExist',
        setTo: false,
        relatedTo: 'siteNotExist',
        required: false,
        defaultChecked: true,
        value: 'on',
        name: 'user_site_exist_yes'
      },
      {
        label: 'Oui',
        // group: 'site',
        type: 'radio',
        id: 'inputSiteExistYes',
        function: 'updateSiteExist',
        setTo: true,
        relatedTo: 'siteExist',
        required: false,
        defaultChecked: false,
        value: 'off',
        name: 'user_site_exist_no'
      }
    ],
    msgInvalid: 'Merci de faire un choix ci-dessus.',
    display: true
  },
  {
    step: 2,
    content: "Quelle est l'adresse de votre site ?",
    type: 'textarea',
    controlId: 'inputWebSite',
    placeholder: 'Exemple : deegity.com',
    msgInvalid: '',
    conditionalDisplay: true,
    required: false,
    relatedTo: 'siteExist',
    xscol: 12,
    display: true,
    name: 'user_site_address',
    rows: 1
  },
  {
    step: 2,
    content:
      "Avez-vous les codes d'accès ? (Admin wordpress, base de données mysql, ftp, accès hébergement etc... )",
    type: 'radio',
    controlId: 'inputCredentials',
    placeholder: '',
    name: 'user_site_credentials',
    inputsRadio: [
      {
        label: 'Non',
        group: 'credentials',
        type: 'radio',
        id: 'inputCredentialsNo',
        function: 'updateCredentialsAlert',
        setTo: false,
        value: 'on',
        defaultChecked: true,
        name: 'user_site_credentials_no',
        required: false
      },
      {
        label: 'Oui',
        group: 'credentials',
        type: 'radio',
        id: 'inputCredentialsYes',
        function: 'updateCredentialsAlert',
        setTo: true,
        value: 'off',
        defaultChecked: false,
        name: 'user_site_credentials_yes',
        required: false
      }
    ],
    msgInvalid: '',
    conditionalDisplay: true,
    relatedTo: 'siteExist',
    alert:
      "Pensez à réunir vos codes d'administration, base de données, ftp, hébergement. Vous nous les communiquerez ultérieurement. ",
    display: true
  },
  {
    step: 2,
    content: "Quelle serait l'adresse idéale pour ce site ?",
    type: 'textarea',
    controlId: 'inputFavAdress',
    placeholder: 'deegity.com',
    msgInvalid: '',
    conditionalDisplay: true,
    required: false,
    relatedTo: 'siteNotExist',
    xscol: 12,
    display: true,
    name: 'user_site_address_wishes',
    rows: 1
  },
  {
    step: 2,
    content:
      "Avez-vous un budget particulier pour l'achat d'un nom de domaine et l'hébergement de ce site ?",
    type: 'textarea',
    controlId: 'inputBudgetHost',
    placeholder: '',
    msgInvalid: '',
    conditionalDisplay: true,
    required: false,
    relatedTo: 'siteNotExist',
    xscol: 12,
    rows: 4,
    display: true,
    name: 'user_site_money'
  },
  {
    step: 2,
    content: 'Avez-vous une préférence pour un hébergeur ? Si oui, lequel ?',
    type: 'textarea',
    controlId: 'inputFavHost',
    placeholder: 'OVH',
    msgInvalid: '',
    conditionalDisplay: true,
    required: false,
    relatedTo: 'siteNotExist',
    xscol: 12,
    display: true,
    name: 'user_site_hosting',
    rows: 1
  },
  {
    step: 2,
    content:
      'Connaissez-vous approximativement la taille des données à héberger ?',
    type: 'textarea',
    controlId: 'inputDataSize',
    placeholder: '100 MB, 1GB...',
    msgInvalid: '',
    conditionalDisplay: false,
    required: false,
    xscol: 12,
    display: true,
    name: 'user_site_data_size',
    rows: 1
  },
  {
    step: 2,
    content:
      "Y a t-il des imports de bases de données à faire ? Si oui, de quel type de base de données s'agit-il ?",
    type: 'textarea',
    controlId: 'inputSiteDB',
    placeholder: 'Exemple : MySQL, PostgreSQL, MySQL Server etc...',
    msgInvalid: '',
    conditionalDisplay: false,
    required: false,
    rows: 2,
    xscol: 12,
    display: true,
    name: 'user_site_import_db'
  },
  {
    step: 3,
    content: "Quel est l'objectif de ce site ?",
    type: 'textarea',
    controlId: 'inputSiteGoal',
    placeholder: 'Augmenter ma visibilité',
    msgInvalid: '',
    conditionalDisplay: false,
    required: false,
    rows: 1,
    xscol: 12,
    display: true,
    name: 'user_site_goal'
  },
  {
    step: 3,
    content:
      "A qui s'adresse ce site ? Quelle est la cible ? (ex : Son genre, son âge, son métier (ou sa catégorie sociale professionnelle)",
    type: 'textarea',
    controlId: 'inputSiteTarget',
    placeholder: '',
    msgInvalid: '',
    conditionalDisplay: false,
    required: false,
    rows: 4,
    xscol: 12,
    display: true,
    name: 'user_site_target'
  },
  {
    step: 3,
    content:
      'Que vient chercher cette cible sur ce site ? (ex : Des informations sur des produits/services, Comment prendre contact (numéro de téléphone, mail…), Se renseigner sur une expertise particulière, prendre un rendez-vous, trouver des offres et tarifs...)',
    type: 'textarea',
    controlId: 'inputSiteLookFor',
    placeholder: '',
    msgInvalid: '',
    conditionalDisplay: false,
    required: false,
    rows: 4,
    xscol: 12,
    display: true,
    name: 'user_site_search'
  },
  {
    step: 4,
    content:
      'Quelles sont toutes les fonctionnalités indispensables à mettre en place sur ce site ? Merci de détailler le plus possible.',
    type: 'textarea',
    controlId: 'inputSiteFeatures',
    placeholder: '',
    msgInvalid: '',
    conditionalDisplay: false,
    required: false,
    rows: 4,
    xscol: 12,
    display: true,
    name: 'user_site_features'
  },
  {
    step: 4,
    content:
      'Quelles sont les fonctionnalités éventuelles à mettre en place dans un deuxième temps ?',
    type: 'textarea',
    controlId: 'inputSiteSecondaryFeatures',
    placeholder: '',
    msgInvalid: '',
    conditionalDisplay: false,
    required: false,
    rows: 4,
    xscol: 12,
    display: true,
    name: 'user_site_features_after'
  },
  {
    step: 4,
    content:
      "Quelle serait l'arborescence de ce site ? Faire un plan détaillé pour savoir comment s'articule le menu, les pages, les catégories, les types de contenus (images, textes, vidéos, fichiers...) etc…",
    type: 'textarea',
    controlId: 'inputSiteMap',
    placeholder: '',
    msgInvalid: '',
    conditionalDisplay: false,
    required: false,
    rows: 4,
    xscol: 12,
    display: true,
    name: 'user_sitemap'
  },
  {
    step: 5,
    content:
      "Dans le cas d'un site où les accès sont restreints, quels sont les types d'utilisateurs et quelles sont leur permissions ? Qui peut voir quoi en fonction de son statut ?",
    type: 'textarea',
    controlId: 'inputRoles',
    placeholder: '',
    msgInvalid: '',
    conditionalDisplay: false,
    required: false,
    rows: 4,
    xscol: 12,
    display: true,
    name: 'user_users_type'
  },
  {
    step: 5,
    content:
      'Y aura t-il des administrateurs et que pourront-ils administrer ?',
    type: 'textarea',
    controlId: 'inputSiteAdmin',
    placeholder: '',
    msgInvalid: '',
    conditionalDisplay: false,
    required: false,
    rows: 4,
    xscol: 12,
    display: true,
    name: 'user_admins'
  },
  {
    step: 6,
    content:
      'Quels sont les sites auxquels vous aimeriez que votre site ressemble et pourquoi ?',
    type: 'textarea',
    controlId: 'inputFavSites',
    placeholder: '',
    msgInvalid: '',
    conditionalDisplay: false,
    required: false,
    rows: 4,
    xscol: 12,
    display: true,
    name: 'user_referring'
  },

  // Autres remarques / commentaires

  {
    step: 6,
    content: 'Quels sont vos concurrents directs ?',
    type: 'textarea',
    controlId: 'inputWebdesign',
    placeholder: '',
    msgInvalid: '',
    conditionalDisplay: false,
    required: false,
    rows: 4,
    xscol: 12,
    display: true,
    name: 'user_concurrent'
  },
  {
    step: 6,
    content:
      'Quel est le délai pour ce projet / quand souhaiteriez-vous le voir en ligne ?',
    type: 'textarea',
    controlId: 'inputDelay',
    placeholder: 'En 20XX',
    msgInvalid: '',
    conditionalDisplay: false,
    required: false,
    xscol: 12,
    display: true,
    name: 'user_delay',
    rows: 1
  },
  {
    step: 6,
    content: 'Autres remarques',
    type: 'textarea',
    controlId: 'inputComments',
    placeholder: '',
    msgInvalid: '',
    conditionalDisplay: false,
    required: false,
    rows: 4,
    xscol: 12,
    display: true,
    name: 'user_comments'
  },
  {
    step: 7,
    content:
      "Souhaiteriez-vous créer d'autres supports tels que des cartes de visite, logos etc... ?",
    type: 'textarea',
    controlId: 'inputOther',
    placeholder: '',
    msgInvalid: '',
    conditionalDisplay: false,
    required: false,
    rows: 4,
    xscol: 12,
    display: false,
    name: 'user_other_creation'
  }
]
