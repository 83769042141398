// REACT COMPONENTS

import React, { useState, useEffect } from 'react'

import { useLocation } from 'react-router-dom'

// REACT ROUTER

import { Link } from 'react-router-dom'

// BOOTSTRAP COMPONENTS

import { Nav } from 'react-bootstrap'

function ItemsMenu() {
  let location = useLocation()

  const localItemSaved = localStorage.getItem('localItemSaved')
  const [localItem, updateLocalItem] = useState(
    localItemSaved ? JSON.parse(localItemSaved) : 'Accueil'
  )
  const menuMobile = document.getElementsByClassName('toggler')[0]
  const menuMobileFixed = document.getElementsByClassName('toggler-fixed')[0]

  const closeMenu = () => {
    menuMobile.checked = false
    menuMobileFixed.checked = false
  }

  useEffect(() => {
    // Use location here instead of passsing params from header because items Menu is a child component from header and it caused error while updating state
    if (location.pathname === '/') {
      updateLocalItem('accueil')
    } else if (location.pathname.startsWith('/a-propos')) {
      updateLocalItem('qui')
    } else if (location.pathname.startsWith('/realisation')) {
      updateLocalItem('real')
    } else if (location.pathname.startsWith('/offre')) {
      updateLocalItem('offres')
    } else if (location.pathname.startsWith('/service')) {
      updateLocalItem('services')
    } else if (location.pathname.startsWith('/contact')) {
      updateLocalItem('contact')
    } else {
      updateLocalItem('accueil')
    }
  })

  useEffect(() => {
    // LOCAL STORAGE ITEMS
    const localItemSet = localStorage.setItem(
      'activeItem',
      JSON.stringify(localItem)
    )
  }, [localItem])

  return (
    <>
      <Nav.Item
        onClick={closeMenu}
        className={`animate__animated animate__flipInX animate__delay-1s ${
          localItem === 'accueil' ? 'activeMenu' : ''
        }`}
      >
        <Link className={`text-decoration-none`} to="/">
          Accueil
        </Link>
      </Nav.Item>
      <Nav.Item
        onClick={closeMenu}
        className={`animate__animated animate__flipInX animate__delay-1s ${
          localItem === 'real' ? 'activeMenu' : ''
        }`}
      >
        <Link className={`text-decoration-none`} to="/realisations">
          Réalisations
        </Link>
      </Nav.Item>
      <Nav.Item
        onClick={closeMenu}
        className={`animate__animated animate__flipInX animate__delay-1s ${
          localItem === 'services' ? 'activeMenu' : ''
        }`}
      >
        <Link className={`text-decoration-none`} to="/services">
          Services
        </Link>
      </Nav.Item>
      <Nav.Item
        onClick={closeMenu}
        title="Offres"
        className={`animate__animated animate__flipInX animate__delay-1s ${
          localItem === 'offres' ? 'activeMenu' : ''
        }`}
      >
        <Link className={`text-decoration-none`} to="/offres">
          Offres
        </Link>
      </Nav.Item>
      <Nav.Item
        onClick={closeMenu}
        className={`animate__animated animate__flipInX animate__delay-1s ${
          localItem === 'qui' ? 'activeMenu' : ''
        }`}
      >
        <Link className={`text-decoration-none`} to="/a-propos">
          À propos
        </Link>
      </Nav.Item>
      <Nav.Item
        onClick={closeMenu}
        className={`animate__animated animate__flipInX animate__delay-1s ${
          localItem === 'contact' ? 'activeMenu' : ''
        }`}
      >
        <Link className={`text-decoration-none`} to="/contact">
          Contact
        </Link>
      </Nav.Item>
    </>
  )
}
export default ItemsMenu
