// REACT COMPONENTS

import React from 'react'

import { offersList } from '../../assets/datas/offers'

// MY COMPONENTS

import Offer from '../../components/offers/offer'

// REACT BOOTSTRAP

import { Col } from 'react-bootstrap'

function Offers() {
  return (
    <>
      {offersList.map(
        (
          {
            id,
            cover,
            title,
            subtitle,
            price,
            recurrence,
            discount,
            priceDiscount,
            target,
            servicesIncluded
          },
          index
        ) => (
          <Col
            key={index}
            xs={{
              span: 12,
              order: id === 'offer-2' ? 'first' : id === 'offer-3' ? 'last' : 2
            }}
            lg={{
              span: 4,
              order: id === 'offer-2' ? 2 : id === 'offer-3' ? 3 : 1
            }}
          >
            <Offer
              id={id}
              cover={cover}
              title={title}
              subtitle={subtitle}
              price={price}
              recurrence={recurrence}
              priceDiscount={priceDiscount}
              discount={discount}
              target={target}
              servicesIncluded={servicesIncluded}
              index={index}
            />
          </Col>
        )
      )}
    </>
  )
}

export default Offers
