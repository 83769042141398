// STYLE

import './style/style.scss'

// REACT LIBS

import React from 'react'
import ReactDOM from 'react-dom/client'
import { hydrate, render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

// COMPONENTS

import Header from './components/header'
import Footer from './components/footer'
import App from './components/app'
import GoTop from './components/various/gotop'

// CONTEXT

import {
  ThemeProvider,
  RealisationsProvider,
  AnimationsProvider
} from './utils/context'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fas, far)

// BOOTSTRAP

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <RealisationsProvider>
        <BrowserRouter>
          <AnimationsProvider>
            <HelmetProvider>
              <Header />
              <App />
              <Footer />
              <GoTop />
            </HelmetProvider>
          </AnimationsProvider>
        </BrowserRouter>
      </RealisationsProvider>
    </ThemeProvider>
  </React.StrictMode>
)
