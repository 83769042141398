// REACT COMPONENTS

import React, { useState } from 'react'

// DATAS

import { recommendations } from '../../assets/datas/recommendations'

// UTILS HOOKS

// COMPONENTS

import Recommendation from './recommendation'

// REACT BOOTSTRAP

import { Col, Carousel } from 'react-bootstrap'

function Recommendations() {
  const [carouselIndex, setCarouselIndex] = useState(0)
  const handleSelect = (selectedIndex, e) => {
    setCarouselIndex(selectedIndex)
  }
  return (
    <>
      <Col xs={12} lg={8}>
        <Carousel
          activeIndex={carouselIndex}
          onSelect={handleSelect}
          controls={false}
          indicators={false}
          interval={2000}
          pause={false}
        >
          {recommendations.map(
            (
              {
                logo,
                comment,
                contributor,
                job,
                picture,
                id,
                company,
                realisationLinkedName
              },
              index
            ) => (
              <Carousel.Item key={`${id}`}>
                <Recommendation
                  logo={logo}
                  comment={comment}
                  contributor={contributor}
                  job={job}
                  picture={picture}
                  id={id}
                  company={company}
                  index={index}
                  realisationLinkedName={realisationLinkedName}
                />
              </Carousel.Item>
            )
          )}
        </Carousel>
      </Col>
    </>
  )
}

export default Recommendations
