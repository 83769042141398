// REACT COMPONENTS

import React from 'react'

// REACT ROUTER

import { Link } from 'react-router-dom'

// BOOTSTRAP COMPONENTS

import { Container, Row, Col, Image } from 'react-bootstrap'

// ASSETS

import Working from '../../assets/img/skills/development.svg'

function Soon() {
  return (
    <Container fluid className="pb-5 px-5">
      <Container className="animate__animated animate__bounceInUp">
        <Row>
          <Col xs={{ span: 12, offset: 0 }} className="text-center">
            <header>
              <h1
                id="title-offers"
                className={`title-anim huge-text-4 mb-2 text-colored-secondary-letter`}
              >
                Ça arrive bientôt ! 🚀
              </h1>
              <p className="fw-light opacity-100 mb-5 fs-4">
                Ne pas déranger votre humble et dévoué développeur....🤫
              </p>
            </header>
          </Col>
        </Row>
        <Row className="gx-5 mt-4 d-flex justify-content-center">
          <Col xs={12} className="d-flex justify-content-center">
            <Image src={Working} className="w-100 drop-shadow" />
          </Col>
        </Row>
        <Row className="hstack justify-content-center my-5">
          <Col xs={12} md={6} className="my-3 d-flex justify-content-center">
            <Link to={`/`} className={`dgt-btn dgt-cta`}>
              Retourner à l'accueil
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
export default Soon
