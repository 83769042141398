// REACT COMPONENTS

import React, { useEffect, useState, useRef } from 'react'

// REACT ROUTER

import { Link } from 'react-router-dom'

// DATAS

import { pros, wishes } from '../../assets/datas/homepage/pros'

// HOOKS

import { useScrollDirection } from '../../utils/hooks'

// REACT BOOTSTRAP

import { Row, Col, Image } from 'react-bootstrap'

// ASSETS

import DarkVaDor from '../../assets/img/icons/darth-vador.svg'

// HELPERS

import { getRandomInt } from '../../utils/helpers'

// FONTAWESOME

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function JobAnimation({ pro, setPro }) {
  const windowHeight = useRef()
  const fixSmallScreen = useRef(false)
  const { scrollY } = useScrollDirection()
  const jokeOnly = false
  const [active, setActive] = useState(true)
  const [wish, setWish] = useState(wishes[4])
  const prosLength = pros.length
  const wishesLength = wishes.length

  let randomNumberPros
  let randomNumberWishes

  const randomize = (random, length, set, arr) => {
    random = getRandomInt(length)
    if (jokeOnly) {
      while (!arr[random].isAJoke) {
        random = getRandomInt(length)
      }
    } else if (countAnim.current < 5) {
      while (arr[random].isAJoke) {
        random = getRandomInt(length)
      }
    } else random = getRandomInt(length)
    set(arr[random])
  }

  const lastTimeAnim = useRef(null)
  const counterAnim = useRef(0)
  const countAnim = useRef(0)

  const jobAnim = (time) => {
    if (lastTimeAnim.current !== null) {
      if (scrollY.current < 300) {
        const delta = time - lastTimeAnim.current
        counterAnim.current += delta * 0.1

        if (counterAnim.current > 330 && counterAnim.current < 370)
          setActive(false)
        if (counterAnim.current > 370) {
          randomize(randomNumberPros, prosLength, setPro, pros)
          randomize(randomNumberWishes, wishesLength, setWish, wishes)
          setActive(true)
          counterAnim.current = 0
          countAnim.current++
        }
      } else return
    }

    lastTimeAnim.current = time
    window.requestAnimationFrame(jobAnim)
  }

  useEffect(() => {
    window.requestAnimationFrame(jobAnim)
    windowHeight.current = window.innerHeight
    if (windowHeight.current <= 568) fixSmallScreen.current = true
  }, [])

  useEffect(() => {
    if (scrollY.current > 300) return
    window.requestAnimationFrame(jobAnim)
  }, [scrollY.current])

  useEffect(() => {
    return () => window.cancelAnimationFrame(jobAnim)
  }, [active])

  return (
    <>
      <Row className="h-100">
        <Col
          xs={12}
          lg={6}
          id="col-presentation"
          className="animate__animated animate__fadeInLeft animate__delay-1s pe-lg-5 px-sm-auto d-flex align-items-stretch align-self-lg-center"
        >
          <section className="d-flex justify-content-around justify-content-lg-start align-items-center align-items-lg-start flex-column col-left-animated">
            {pro.iconFA !== false ? (
              <>
                <FontAwesomeIcon
                  size="3x"
                  icon={pro.iconFA}
                  className={`svg-secondary text-center d-block d-md-none mx-auto animate__animated animate__fast ${
                    active ? 'animate__zoomIn' : 'animate__zoomOut'
                  }`}
                />
                <FontAwesomeIcon
                  size="5x"
                  icon={pro.iconFA}
                  className={`svg-secondary text-center d-none d-md-block mx-auto animate__animated animate__fast ${
                    active ? 'animate__zoomIn' : 'animate__zoomOut'
                  }`}
                />
              </>
            ) : pro.otherIcon && pro.otherIcon.length > 0 ? (
              <div
                className={`w-100 huge-text-4 text-center animate__animated no-gradient ${
                  active ? 'animate__zoomIn' : 'animate__zoomOut'
                }`}
              >
                {pro.otherIcon}
              </div>
            ) : pro.iconPath && pro.iconPath.length > 0 ? (
              <div className="d-flex justify-content-center align-items-center flex-row w-100">
                <div className="w-25 d-flex justify-content-center">
                  <Image src={DarkVaDor} className="d-block w-50"></Image>
                </div>
              </div>
            ) : null}
            <h2
              className={`mt-0 mb-0 mt-lg-5 mb-lg-5 huge-text-3 text-left no-gradient text-animated`}
            >
              Vous êtes
              <span
                className={`bg-text text-colored-secondary animate__animated ${
                  active ? 'animate__zoomIn' : 'animate__zoomOut'
                }`}
              >
                &nbsp;{pro.jobRelated}&nbsp;
              </span>
              et vous voulez
              <span
                className={`text-colored-secondary bg-text animate__animated ${
                  active ? 'animate__zoomIn' : 'animate__zoomOut'
                }`}
              >
                &nbsp;{wish.sentence}&nbsp;?
              </span>
            </h2>
            <p className={`fs-5 fw-light d-none d-lg-block`}>
              <span className="fs-2">🚀 </span>
              <span className="fw-bold">Boostez votre présence digitale </span>
              avec nos offres !
            </p>
            <p className={`${fixSmallScreen.current && 'd-none'}`}>
              Quelle que soit votre secteur d'activité,{' '}
              <span className="fw-semibold">Deegity</span> vous fait bénéficier
              de{' '}
              <span className="fw-semibold">solutions digitales adaptées</span>{' '}
              à votre entreprise avec{' '}
              <span className="fw-semibold">efficacité et créativité</span> !
            </p>
            <Link
              to={'/contact'}
              className="d-inline-flex dgt-btn dgt-cta fs-6"
            >
              Nous contacter
            </Link>
          </section>
        </Col>
        <Col
          id="col-bg-overlay"
          lg={6}
          className="flex-column bg-overlay-responsive d-none d-lg-flex animate__animated animate__fadeInRight animate__delay-1s"
        ></Col>
      </Row>
      <Row className="d-none d-lg-block">
        <a className="arrow-anim bottom-0" href="#title-realisation">
          <span className="arrow-left"></span>
          <span className="arrow-right"></span>
        </a>
      </Row>
    </>
  )
}
export default JobAnimation
