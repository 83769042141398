import React, { useEffect, useState } from 'react'
import { realisationsList } from '../../assets/datas/realisations'
import { ToggleButton } from 'react-bootstrap'
import { useRealisations } from '../../utils/hooks'
import { useTheme } from '../../utils/hooks'

function Filters({
  filter,
  id,
  keyFilter,
  checked,
  setChecked,
  disabled,
  updateRealisationList,
  realisationsFiltered,
  updateRealisationsFiltered
}) {
  const {
    setDisabledCompanies,
    setDisabledCategories,
    setDisabledYears,
    otherCat,
    setOtherCat,
    selectedFilters,
    updateSelectedFilters,
    addOrRemove,
    setAddOrRemove,
    lastCategSelected,
    setLastCategSelected,
    filterSelected,
    setFilterSelected,
    nextFiltersCateg,
    setNextFiltersCateg,
    filteredCategories,
    filteredCompanies,
    filteredYears
  } = useRealisations()

  const { theme } = useTheme()

  const realisationsInit = realisationsList
  const [nextStep, setNextStep] = useState(0)

  useEffect(() => {
    updateSelectedFilters([])
    const reset = checked.map((elem, index) => {
      return elem ? !elem : elem
    })
    setChecked(reset)
    if (nextStep !== 0 && selectedFilters.length > 0) return
    setNextStep(1)
  }, [])

  useEffect(() => {
    if (nextStep !== 1) return
    if (selectedFilters.length === 0) {
      setCats()
      return
    }
    const setNext = setNextFilters(selectedFilters)

    setNextFiltersCateg(setNext)
    lastCateg()
    setNextStep(2)
  }, [selectedFilters])

  useEffect(() => {
    if (nextStep !== 2) return
    isOtherCateg()
    setNextStep(3)
  }, [nextStep])

  useEffect(() => {
    if (nextStep !== 3) return
    realisationsUpdateGlobal(addOrRemove, selectedFilters)
    setNextStep(4)
  }, [nextStep])

  useEffect(() => {
    if (nextStep !== 4) return
    updateRealisationList(realisationsFiltered)
    setNextStep(5)
  }, [nextStep])

  useEffect(() => {
    if (nextStep !== 5) return
    setCats()
    setNextStep(1) // Si on met 0 et qu'on a selected des filtres alors le traitement ne se fera jamais
  }, [nextStep])

  const displayOnlyAvailable = (e, position, filterName) => {
    // UPDATE THE SELECTED FILTER STATUS IN HIS OWN GROUP OF FILTERS

    const updatedChecked = checked.map((item, index) =>
      index === position ? !item : item
    )
    setChecked(updatedChecked)
    // UPDATE SELECTED FILTERS STATE

    // IF ADD FILTER
    if (updatedChecked[position]) {
      setAddOrRemove(true)
      updateSelectedFilters([
        ...selectedFilters,
        { filter: filterName, cat: keyFilter, pos: position }
      ])
      setFilterSelected(keyFilter)
      // isFiltering('addFilter')
    } else {
      e.target.blur()
      // IF REMOVE ONE FILTER
      setAddOrRemove(false)
      const onlyFiltersChecked = selectedFilters.filter(
        (selected) => selected['filter'] !== filterName
      )
      // on fait un update ici contrairement à add filter mode car on a besoin d'avoir des infos grâces aux trois dernières fonctions afin de faire un update global des projets
      // isFiltering('removeFilter')
      updateSelectedFilters(onlyFiltersChecked)
    }
  }

  /////////////////////////////////////////////////

  const lastCateg = () => {
    if (selectedFilters.length > 1) {
      setLastCategSelected(selectedFilters[selectedFilters.length - 2]['cat'])
    } else if (selectedFilters.length === 1) {
      setLastCategSelected(selectedFilters[0]['cat'])
    } else setLastCategSelected('')
  }

  const isOtherCateg = () => {
    // la dernière categ sélectionnée est-elle différente de la catégorie de filtre qu'on vient de cliquer ?
    // et existe t-elle ?
    lastCategSelected !== filterSelected &&
    lastCategSelected !== '' &&
    addOrRemove
      ? setOtherCat(true)
      : setOtherCat(false)
  }

  /////////////////////////////////////////////////

  // FILTER REALISATIONS

  const filterRealisations = (realisationsToFilter, arrFilters, filter) => {
    if (arrFilters) {
      return realisationsToFilter.map((realisation) =>
        arrFilters.includes(realisation.category) ||
        arrFilters.includes(realisation.company) ||
        arrFilters.includes(realisation.year)
          ? realisation
          : undefined
      )
    } else if (filter) {
      return realisationsToFilter.map((realisation) =>
        filter === realisation.category ||
        filter === realisation.company ||
        filter === realisation.year
          ? realisation
          : undefined
      )
    }
  }

  // REMOVE DUPLICATES OPTION 1 AVEC ACC

  const removeDuplicates = (arrayToParse) => {
    return (arrayToParse = arrayToParse.reduce(
      (acc, realisation) =>
        acc.includes(realisation.id) ? acc : acc.concat(realisation),
      []
    ))
  }

  // REMOVE DUPLICATES OPTION 2 AVEC FILTER ET FINDINDEX

  // function removeDuplicates(arrayToParse) {
  //   return (arrayToParse = arrayToParse.filter(
  //     (value, index, self) =>
  //       index === self.findIndex((t) => t.id === value.id)
  //   ))
  // }

  // REMOVE UNDEFINED REALISATIONS

  const removeUndefinedRealisations = (realisations) => {
    return realisations.filter((realisation) => realisation !== undefined)
  }

  const setNextFilters = (selected) => {
    return selected.filter(
      //  tous les obj filtres de categ != du premier selected
      (filterobj) => filterobj.cat !== selected[0].cat
    )
  }

  /////////////////////////////////////////////////

  // CREATE A NEW ARRAY OF REALISATIONS WITH ONLY THOSE WHO MATCHED WITH SELECTED FILTERS

  const realisationsUpdateGlobal = (tick, onlyChecked) => {
    let lastFilterSelected = onlyChecked[onlyChecked.length - 1]['filter']
    // IF SAME CATEGORY IS SELECTED AND ONLY ONE CATEG SELECTED
    if (!otherCat && tick && nextFiltersCateg.length === 0) {
      const arrProjFiltered = realisationsInit.filter(
        (realisation) => realisation[filterSelected] === lastFilterSelected
      )
      if (arrProjFiltered.length > 0) {
        let array = [...realisationsFiltered, ...arrProjFiltered]

        removeDuplicates(array)
        updateRealisationsFiltered(array)
      }
      // IF ANOTHER CATEGORY IS SELECTED
    } else if (otherCat && tick) {
      const onlyLinkedRealisations = realisationsFiltered.filter(
        (realisation) => realisation[filterSelected] === lastFilterSelected
      )
      if (onlyLinkedRealisations.length > 0)
        updateRealisationsFiltered(onlyLinkedRealisations)
      else return
      // IF UNTICK ONE FILTER OU SI par ex: select 2020 puis company 1 puis company 2
    } else if (!tick || (!otherCat && tick && nextFiltersCateg.length > 0)) {
      const onlyFiltersSameCateg = onlyChecked.filter(
        // tous les obj filtres de mm categ que le premier selected
        (filterobj) => filterobj.cat === onlyChecked[0].cat
      )
      const newArr = onlyFiltersSameCateg.map((filterobj) => filterobj.filter) // tableau avec uniquement les noms des filtres
      const preMatchedRealisations = filterRealisations(
        realisationsInit,
        newArr,
        undefined
      )
      const matchedRealisationsPure = removeUndefinedRealisations(
        preMatchedRealisations
      ) // on vire les undefined

      let matchedRealisationsPureNext = []
      let matchedRealisationsPureNextGlobal = []

      for (let i = 0; i < nextFiltersCateg.length; i++) {
        // parmi les projets filtrés précédemment "matchedRealisationsPure" on fait un second filtrage avec les autres filtres sélectionnés de catégorie différente
        const preMatchedRealisationsNext = filterRealisations(
          matchedRealisationsPure,
          undefined,
          nextFiltersCateg[i].filter
        )
        // on vire ceux qui ne matchent pas
        matchedRealisationsPureNext = removeUndefinedRealisations(
          preMatchedRealisationsNext
        )
        matchedRealisationsPureNextGlobal = [
          ...matchedRealisationsPureNextGlobal,
          ...matchedRealisationsPureNext
        ]
      }
      if (matchedRealisationsPureNextGlobal.length > 0) {
        // Ci-dessous on enlève les éventuels realisations doublons
        matchedRealisationsPureNextGlobal = removeDuplicates(
          matchedRealisationsPureNextGlobal
        )

        updateRealisationsFiltered(matchedRealisationsPureNextGlobal)
      } else updateRealisationsFiltered(matchedRealisationsPure)
    } else return
  }

  // MAKE APPEAR OR DISAPPEAR FILTERS

  const setCats = () => {
    const everyYearRealisation = []
    const everyCategoryRealisation = []
    const everyCompanyRealisation = []

    const matchCateg = []
    const matchYear = []
    const matchCompany = []

    function everyFilter(param, arr) {
      realisationsFiltered.filter((realisation, index) =>
        realisation[param] ? (arr[index] = realisation[param]) : undefined
      )
    }

    function matchCat(arrFiltered, everyCat, match) {
      arrFiltered.filter((filter, index) =>
        everyCat.includes(filter)
          ? (match[index] = false)
          : (match[index] = true)
      )
    }

    if (realisationsFiltered.length > 0) {
      if (nextFiltersCateg.length === 0 && selectedFilters.length > 0) {
        switch (selectedFilters[0].cat) {
          case 'category':
            everyFilter('year', everyYearRealisation)
            everyFilter('company', everyCompanyRealisation)
            matchCat(filteredYears, everyYearRealisation, matchYear)
            matchCat(filteredCompanies, everyCompanyRealisation, matchCompany)
            setDisabledCompanies(matchCompany)
            setDisabledYears(matchYear)
            setDisabledCategories(
              new Array(filteredCategories.length).fill(false)
            )
            break

          case 'year':
            everyFilter('category', everyCategoryRealisation)
            everyFilter('company', everyCompanyRealisation)
            matchCat(filteredCategories, everyCategoryRealisation, matchCateg)
            matchCat(filteredCompanies, everyCompanyRealisation, matchCompany)
            setDisabledCategories(matchCateg)
            setDisabledCompanies(matchCompany)
            setDisabledYears(new Array(filteredYears.length).fill(false))
            break

          case 'company':
            everyFilter('year', everyYearRealisation)
            everyFilter('category', everyCategoryRealisation)
            matchCat(filteredYears, everyYearRealisation, matchYear)
            matchCat(filteredCategories, everyCategoryRealisation, matchCateg)
            setDisabledCategories(matchCateg)
            setDisabledYears(matchYear)
            setDisabledCompanies(
              new Array(filteredCompanies.length).fill(false)
            )
            break

          default:
            break
        }
      } else {
        everyFilter('year', everyYearRealisation)
        everyFilter('category', everyCategoryRealisation)
        everyFilter('company', everyCompanyRealisation)
        matchCat(filteredYears, everyYearRealisation, matchYear)
        matchCat(filteredCompanies, everyCompanyRealisation, matchCompany)
        matchCat(filteredCategories, everyCategoryRealisation, matchCateg)
        setDisabledCategories(matchCateg)
        setDisabledYears(matchYear)
        setDisabledCompanies(matchCompany)
      }
    }

    if (selectedFilters.length === 0) {
      setDisabledCategories(new Array(filteredCategories.length).fill(false))
      setDisabledYears(new Array(filteredYears.length).fill(false))
      setDisabledCompanies(new Array(filteredCompanies.length).fill(false))
    }
  }

  return (
    <>
      {filter.map((filterName, index) => (
        <ToggleButton
          key={index}
          className={`${
            theme === 'light' ? 'outline-dgt-primary ' : 'outline-dgt-white '
          } ${
            disabled[index]
              ? 'animate__animated animate__backOutLeft filter-disabled'
              : 'animate__animated animate__backInLeft filter-enabled'
          } d-block mb-3 filters`}
          id={`toggle-check-${id}`}
          type="checkbox"
          variant={`${theme === 'light' ? 'outline-primary' : 'outline-light'}`}
          checked={checked[index]}
          value={filterName}
          onClick={(e) => displayOnlyAvailable(e, index, filterName)}
          disabled={disabled[index]}
        >
          {filterName}
        </ToggleButton>
      ))}
    </>
  )
}

export default Filters
