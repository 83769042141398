// REACT COMPONENTS

import React from 'react'

// META TAGS

import { Helmet } from 'react-helmet-async'

// HOOKS

import { useTheme } from '../../utils/hooks'

// BOOTSTRAP COMPONENTS

import { Container, Row, Col } from 'react-bootstrap'

// IMAGES

import deegityThumb from '../../assets/img/logos/deegity/deegity-thumb.jpg'

function Credits() {
  const { theme } = useTheme()
  return (
    <>
      <Helmet>
        <title>Crédits</title>
        <meta property="og:title" content="Crédits" />
        <meta property="og:description" content="Crédits, remerciements" />
        <meta name="description" content="Crédits, remerciements" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta
          property="og:image:secure_url"
          content={`https://www.deegity.com${deegityThumb}`}
        />
        <meta property="og:url" content={`https://www.deegity.com/credits`} />
      </Helmet>
      <Container
        fluid
        className={`credits-dgt ${
          theme === 'light'
            ? 'bg-lightGray-no-border-neutral'
            : 'bg-pattern-dark-mode'
        }`}
      >
        <Container>
          <Row id="new-realisations">
            <Col>
              <h1
                id="title-realisation"
                className={`title-anim huge-text-4 text-colored-secondary-letter text-center `}
              >
                Crédits
              </h1>
            </Col>
          </Row>
          <Row>
            <ul className="list-group">
              <li className="list-item">
                <a
                  target="_blank"
                  href="https://www.freepik.com/free-vector/responsive-design-isometric-landing-user-interface_20836284.htm#query=web%20design&position=49&from_view=search"
                  rel="noreferrer"
                >
                  Image by upklyak on Freepik
                </a>
              </li>
            </ul>
          </Row>
        </Container>
      </Container>
    </>
  )
}

export default Credits
