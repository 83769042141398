// REACT COMPONENTS

import React, { useEffect, useState } from 'react'

// META TAGS

import { Helmet } from 'react-helmet-async'

// REACT ROUTER

import { Link } from 'react-router-dom'

// MY HELPERS

import { getRandomInt } from '../../utils/helpers'

// HOOKS

import { useTheme } from '../../utils/hooks'

// REACT BOOTSTRAP

import { Container, Col, Row, Image } from 'react-bootstrap'

// ASSETS

import btcFlash from '../../assets/img/various/btc-flashcode.svg'

function Error() {
  const theme = useTheme()
  const [gifPath, setGifPath] = useState('')
  useEffect(() => {
    const arrGifs = [
      'VbAFrrDVGAvZu',
      'ySpxjJmsq9gsw',
      'ui4VjMUBGXhwgdwUnK',
      'd10dMmzqCYqQ0',
      '15aGGXfSlat2dP6ohs',
      '1E1msx7Yw5Ne1Fe',
      '9G3wg7lH5DpxC',
      'hFmIU5GQF18Aw',
      'l3vRj0W7EWM7H04la',
      '12mz9OgbNNOYc8',
      'dDPbeVUudpdNC',
      'Zb0Xzgma68CT1nr8NI',
      'g01ZnwAUvutuK8GIQn',
      'XEZZliwJmGg6otzKK1',
      'rwNpHtaMGnStW',
      'PtGExXJfTYiKA',
      'xTiTnooneW4SYfch8Y',
      'Ub8XEam5vXbMY',
      'NDzYciTuZlOFy',
      'OUnQvYHi6AJva'
    ]
    const number = getRandomInt(arrGifs.length + 1)
    setGifPath(arrGifs[number])
  }, [])
  return (
    <>
      <Container
        className={`my-5 pb-5 error-container ${
          theme === 'light' ? 'bg-lightGray-no-top' : ''
        }`}
      >
        <Row>
          <Col
            xs={12}
            lg={6}
            className="justify-content-center d-flex flex-column animate__animated animate__fadeInLeft animate__fast"
          >
            <h2 className="text-colored-secondary-letter huge-text-4 mb-2 mt-2 text-center text-lg-start animate__animated animate__flipInX animate__delay-1s">
              Erreur 404
            </h2>
            <p className="mb-5 fs-4 text-center text-lg-start">
              <span className="fw-light">
                Cette page n'existe que dans votre imagination&nbsp;&nbsp;
              </span>
              🧠
              <span className="position-relative cloud-404 inline-block">
                💭
              </span>
              <br></br>
              <span className="fw-light">
                Pour la peine, faites un don en scannant ce QR Code
              </span>
              <br />
              👇👇👇👇👇👇
            </p>

            <Row className="align-items-center justify-content-center justify-content-lg-start">
              <Col xs={12} lg={'auto'}>
                <Image
                  src={btcFlash}
                  alt="bitcoin flashcode"
                  className="icon-svg icon-md d-flex m-auto mb-lg-0 mb-5"
                />
              </Col>
              <Col xs={12} lg={{ span: 7 }}>
                <Link
                  className="ms-3 dgt-btn dgt-cta d-block"
                  to="/"
                  title="Accueil"
                >
                  J'aime pas les QR Code
                </Link>
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            lg={6}
            className="d-none d-lg-block animate__animated animate__fadeInRight"
          >
            <iframe
              src={`https://giphy.com/embed/${
                gifPath ? gifPath : 'VbAFrrDVGAvZu'
              }`}
              width="100%"
              height="400"
              frameBorder="0"
              className="giphy-embed"
              allowFullScreen
              title="404-Deegity"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Error
