// REACT

import React from 'react'

//FONTAWESOME

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ContactMe({ subject, body }) {
  return (
    <>
      <div className="hover-colored">
        <FontAwesomeIcon
          size="lg"
          icon="fa-solid fa-paper-plane"
          className="me-2"
        />
        <a
          href={`mailto:contact@deegity.com?subject=${subject}&body=${body}`}
          className="text-decoration-none text-white"
        >
          <u className="link-dgt">contact@deegity.com</u>
        </a>
      </div>
    </>
  )
}

export default ContactMe
