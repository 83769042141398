// LOGOS CLIENTS / COMPANIES ORIGINAL

import gaultmillau from '../../img/logos/companies/original/gaultmillau.svg'
import gcbc from '../../img/logos/customers/original/gcbc.png'
import nicolasbesson from '../../img/logos/customers/original/nicolas-besson.svg'
import streamwide from '../../img/logos/customers/original/streamwide.png'
import celergen from '../../img/logos/customers/original/celergen.png'

// LOGOS CLIENTS / COMPANIES WHITE

import gaultmillauWhite from '../../img/logos/companies/white/gaultmillau.svg'
import nicolasbessonWhite from '../../img/logos/customers/white/nicolas-besson.svg'
import streamwideWhite from '../../img/logos/customers/white/streamwide.png'

export const customers = [
  {
    name: 'Nicolas Besson',
    description: 'Couturier',
    logo: nicolasbesson,
    logoWhite: nicolasbessonWhite,
    link: 'https://www.nicolasbesson.com',
    category: 'Mode',
    tags: ['Haute couture', 'Fashion']
  },
  {
    name: 'GCBC UCPA',
    description: 'Groupement des Chefs de Bord de Croisière',
    logo: gcbc,
    logoWhite: null,
    link: 'https://www.gcbcucpa.com',
    category: 'Association',
    tags: ['Navigation', 'Bâteau', 'Événements']
  },
  {
    name: 'Gault&Millau',
    description: "Guide culinaire d'excellence depuis plus de 50 ans",
    logo: gaultmillau,
    logoWhite: gaultmillauWhite,
    link: 'https://fr.gaultmillau.com',
    category: 'Alimentation',
    tags: ['Guide', 'Food & drink', 'Restauration', 'Gastronomie']
  },
  {
    name: 'Celergen',
    description:
      'Celergen is a potent Non Injectable Swiss Cell Therapy Treatment that stimulates our own natural healing powers',
    logo: celergen,
    logoWhite: null,
    link: 'https://celergenswiss.com',
    category: 'Santé',
    tags: ['Thérapie', 'Traitement', 'Soins']
  },
  {
    name: 'Streamwide',
    description:
      'STREAMWIDE est l’un des principaux fournisseurs des technologies logicielles de communication proposant des solutions de collaboration et  de communication pour les entreprises et pour les missions critiques',
    logo: streamwide,
    logoWhite: streamwideWhite,
    link: 'https://www.streamwide.com',
    category: 'Communication',
    tags: ['B2B', 'Solutions de collaboration']
  }
]
