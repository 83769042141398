export const offersList = [
  {
    id: 'offer-1',
    cover: '',
    title: 'Silver',
    subtitle: "L'offre pour bien démarrer",
    price: '2 000 € HT',
    discount: false,
    priceDiscount: '',
    target: '<span class="fst-italic d-block mt-2">Site vitrine</span>',
    servicesIncluded: {
      pages: '<span class="fw-semibold">1 page</span><br>'
    }
  },
  {
    id: 'offer-2',
    cover: '',
    title: 'Gold',
    subtitle: "L'offre équilibrée pour les professionnels",
    price: '3 000 € HT',
    discount: false,
    priceDiscount: '',
    target:
      '<span class="fst-italic d-block mt-2">Site vitrine / institutionnel / blog</span>',
    servicesIncluded: {
      pages: 'Jusqu\'à <span class="fw-semibold">5 pages</span>'
    }
  },
  {
    id: 'offer-3',
    cover: '',
    title: 'Premium',
    subtitle: 'Le maximum de prestations',
    price: 'Sur devis gratuit',
    discount: false,
    priceDiscount: '',
    target:
      '<span class="fst-italic d-block mt-2">Site vitrine / institutionnel / blog / e-commerce</span>',
    servicesIncluded: {
      pages: 'Nombre de <span class="fw-semibold">pages illimité</span>',
      theme: 'Design <span class="fw-semibold">personnalisé</span>'
    }
  }
]

export const includedAllOffers = [
  {
    description:
      'Adapté pour mobile / tablette / ordinateur et compatible tous navigateurs',
    icon: 'mobile-screen'
  },
  {
    description: 'Mise en valeur de vos photos et textes',
    icon: 'image'
  },
  {
    description: 'Thème à choisir parmi une large sélection',
    icon: 'swatchbook'
  },
  {
    description: 'Site sécurisé HTTPS',
    icon: 'lock'
  },
  {
    description: 'Formulaire de contact',
    icon: 'address-book'
  },
  {
    description: 'Référencement optimisé',
    icon: 'magnifying-glass'
  },
  {
    description: 'Site à votre image',
    icon: 'image-portrait'
  },
  {
    description: 'Facilement administrable',
    icon: 'gear'
  },
  {
    description: 'Site ultra rapide',
    icon: 'gauge-high'
  },
  {
    description: 'SAV premium',
    icon: 'screwdriver-wrench'
  },
  {
    description: "Évolutif à l'infini",
    icon: 'arrow-trend-up'
  }
]
