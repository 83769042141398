// REACT COMPONENTS

import React, { useEffect, useState, useRef } from 'react'

// BOOTSTRAP COMPONENTS

import { Row, Col, Image } from 'react-bootstrap'

// MY HELPERS

import { getRandomInt } from '../../utils/helpers'

// DATAS

import { customers } from '../../assets/datas/customers'

function Companies() {
  const arrNumber = useRef([0])

  // BETTER USE THIS BUT TOO RESTRICTIVE...

  // const logos = customers.reduce(
  //   (acc, realisation) =>
  //     acc.includes(realisation.logoWhite) || realisation.logoWhite === null
  //       ? acc
  //       : realisation.logoWhite.includes('svg')
  //       ? acc.concat({ src: realisation.logoWhite, link: realisation.link })
  //       : acc,
  //   []
  // )

  const logos = customers.reduce(
    (acc, customer) =>
      acc.includes(customer.logo) || acc.includes(customer.logoWhite)
        ? acc
        : customer.logoWhite && customer.name !== 'Streamwide'
        ? acc.concat({ src: customer.logoWhite, link: customer.link })
        : customer.logo && customer.name !== 'Streamwide'
        ? acc.concat({ src: customer.logo, link: customer.link })
        : acc,
    []
  )

  const elements = useRef()
  const logosLength = useRef()

  const randomNumberLogo = useRef()

  const [logo, setLogo] = useState('logo-anim-5')

  const randomize = (random, length, set, arr) => {
    random = getRandomInt(length + 1)
    if (arr[random]) {
      const elem = arr[random]
      const id = elem.id
      set(id)
    }
  }

  const lastTimeAnim = useRef(null)
  const counterAnim = useRef(0)

  const toAnim = useRef()

  const logoAnim = (time) => {
    if (lastTimeAnim.current !== null) {
      const delta = time - lastTimeAnim.current
      counterAnim.current += delta
      if (counterAnim.current > 1300) {
        counterAnim.current = 0

        toAnim.current.classList.remove('opacity-100', 'animate__flipInX')
        toAnim.current.classList.add('opacity-50')
        randomize(
          randomNumberLogo.current,
          logosLength.current,
          setLogo,
          elements.current
        )
      }
    }
    lastTimeAnim.current = time
    window.requestAnimationFrame(logoAnim)
  }

  // TO ANIMATE : UNCOMMENT BELOW

  useEffect(() => {
    const allClasses = document.getElementsByClassName('logo-anim')
    elements.current = allClasses
    const arrLengthElems = elements.current.length
    logosLength.current = arrLengthElems

    for (let i = 0; i < logosLength.current; i++) {
      const el = elements.current[i]
      el.setAttribute('id', 'logo-anim-' + i)
    }

    // window.requestAnimationFrame(logoAnim)
  }, [])

  // useEffect(() => {
  //   toAnim.current = document.getElementById(logo)
  //   toAnim.current.classList.add('opacity-100', 'animate__flipInX')
  //   toAnim.current.classList.remove('opacity-25')

  //   return () => window.cancelAnimationFrame(logoAnim)
  // }, [logo])

  return (
    <>
      {arrNumber.current.map((number, indexNumber) => (
        <div key={`row-logos-${indexNumber}`}>
          <Row
            className={`d-flex align-items-center justify-content-center position-relative gx-5 row-logos row-logos-${indexNumber}`}
          >
            {logos.map((logo, index) => (
              <Col
                xs={{
                  span: 3,
                  order:
                    index + 1 + indexNumber === 6
                      ? 'order-first'
                      : index + 1 + indexNumber
                }}
                key={`logo-${index}`}
              >
                <a
                  href={logo.link}
                  target="_blank"
                  rel="noreferrer"
                  className="d-block"
                >
                  <Image
                    fluid
                    src={logo.src}
                    className={`m-auto logo-anim d-block animate__animated animate__fast opacity-50`}
                  />
                </a>
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </>
  )
}

export default Companies
