// REACT COMPONENTS

import React, { useEffect, useState } from 'react'

// META TAGS

import { Helmet } from 'react-helmet-async'

// REACT ROUTER

import { useParams, Route, Routes, useLocation, Link } from 'react-router-dom'

// DATAS

import { recommendations } from '../../assets/datas/recommendations'

// COMPONENT

import Recommendation from '../../components/recommendations/recommendation'
import Realisation from '../../components/realisation'

// 404

import Error from '../../components/error'

// HOOKS

import { useTheme, useAnimAppear } from '../../utils/hooks'

// REACT BOOTSTRAP

import { Container, Row, Col, Image, Carousel, Modal } from 'react-bootstrap'

// DATAS

import { realisationsList } from '../../assets/datas/realisations'

//FONTAWESOME

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function RealisationPage() {
  let location = useLocation()

  const { setParamsAnim, paramsAnim, setCountAnimation, animTarget } =
    useAnimAppear()

  const realisationsPage = location.pathname.startsWith('/realisation')
  const [positionArrToAnim, setPositionArrToAnim] = useState()

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  useEffect(() => {
    const realisationsRelated = document.querySelectorAll('[id^="realisation"]')

    setParamsAnim([
      [
        [
          realisationsRelated,
          'animate__animated animate__fadeInRight',
          500,
          'realisationsPageAnim'
        ]
      ],
      location.pathname
    ])
    setCountAnimation(true)
  }, [])

  useEffect(() => {
    if (animTarget.length > 0 && realisationsPage) {
      setPositionArrToAnim(animTarget.indexOf('realisationsPageAnim'))
    }
  }, [animTarget, paramsAnim])

  const [modalShow, setModalShow] = useState(false)
  const { theme } = useTheme()
  const [index, setIndex] = useState(0)
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  const { name, position } = useParams()

  let match

  const realisationName = name

  const realisationFiltered = realisationsList.filter((real, index) => {
    return real.position === parseInt(position)
  })
  const realisation = realisationFiltered[0]

  match = realisationName === name

  const projSameCateg = realisationsList.filter(
    (proj, index) =>
      proj.category === realisation.category && proj.name !== realisation.name
  )

  const recosLinkedToProj = recommendations.filter(
    (reco, index) => reco.realisationLinkedName === realisation.name
  )

  const prevIcon = (
    <FontAwesomeIcon icon="chevron-left" size="3x" className="svg-light" />
  )

  const nextIcon = (
    <FontAwesomeIcon icon="chevron-right" size="3x" className="svg-light" />
  )

  const companyNoSpeCarac = realisation.company
    .replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g, '-')
    .toLowerCase()
  const nameNoSpeCarac = realisation.name
    .replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g, '-')
    .toLowerCase()
  const companyUrlFormat = companyNoSpeCarac
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
  const nameUrlFormat = nameNoSpeCarac
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

  return match && realisation !== null ? (
    <>
      <Helmet>
        <title>{realisation.name}</title>
        <meta property="og:title" content={`${realisation.name}`} />
        <meta
          property="og:description"
          content={`${realisation.description}`}
        />
        <meta name="description" content={`${realisation.description}`} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta
          property="og:image:secure_url"
          content={`https://www.deegity.com${realisation.thumb}`}
        />
        <meta
          property="og:url"
          content={`https://www.deegity.com/realisation/${companyUrlFormat}/${nameUrlFormat}/${realisation.position}`}
        />
      </Helmet>
      <Container
        fluid
        className={`gx-0 position-relative wrapper-carousel-realisation ${
          theme === 'light' ? 'bg-lightGray-no-top' : ''
        }`}
      >
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          pause={modalShow}
          interval={null}
          controls={true}
          prevIcon={realisation.gallery.length > 1 && prevIcon}
          nextIcon={realisation.gallery.length > 1 && nextIcon}
          className="realisation-page-carousel"
        >
          {realisation.gallery.map((picture, index) => (
            <Carousel.Item key={`${realisation.name}-gallery-item-${index}`}>
              <img
                className="d-block w-100"
                src={picture}
                alt={`${realisation.name}-${index}`}
              />
            </Carousel.Item>
          ))}
        </Carousel>

        <div className="header-carousel position-absolute opacity-0 animate__animated animate__fadeInUp">
          <h1 className="huge-text-4 my-0 text-white text-colored-secondary-letter">
            {realisation.name}
          </h1>
          <div className="d-flex flex-row align-items-center justify-content-center">
            {/* <h5 className="text-no-shadow me-3">
              <span className="rounded-3 badge bg-light fw-normal text-capitalize text-black text-no-shadow">
                {realisation.year}
              </span>
            </h5> */}
            <h5 className="text-no-shadow">
              <span className="rounded-3 badge bg-light fw-normal text-capitalize text-black">
                {realisation.category}
              </span>
            </h5>
          </div>
        </div>

        <div
          className="bg-gradient-black position-absolute top-0"
          onClick={() => setModalShow(true)}
        ></div>
      </Container>
      <Container fluid className="my-5">
        <Container>
          <Row className="d-flex flex-column">
            <Col xs={12} lg={{ span: 10, offset: 1 }}>
              <Row className="gx-5">
                <Col
                  xs={12}
                  lg={3}
                  className="d-flex align-items-center justify-content-center shadow-lg rounded-3"
                >
                  <div className="bg-neutral w-100 logo-client d-flex align-items-center justify-content-center p-4 p-lg-3">
                    <Image fluid src={realisation.logo} />
                  </div>
                </Col>
                {realisation.description && (
                  <Col xs={12} lg={{ span: 9 }}>
                    <article
                      className={`text-justify my-4 text-justify fw-light`}
                    >
                      {realisation.description}
                    </article>
                  </Col>
                )}
              </Row>
              <Row className="mt-4">
                <Col xs={12} lg={8}>
                  <ul className="d-flex flex-row flex-wrap px-0 list-unstyled mt-2 mb-4">
                    {realisation.techno.map((tec, index) => (
                      <li
                        className="me-2 mb-1"
                        key={`${realisation.name}-teclist-${index}`}
                      >
                        <span className="fw-normal badge rounded-pill text-bg-light">
                          {tec}
                        </span>
                      </li>
                    ))}
                  </ul>
                </Col>
                {realisation.link ? (
                  <Col
                    xs={12}
                    lg={4}
                    className="d-flex justify-content-md-end justify-content-center align-items-center"
                  >
                    <a
                      href={realisation.link}
                      className="dgt-btn dgt-cta"
                      title={realisation.name}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      Voir le site
                    </a>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>

          {recosLinkedToProj.length > 0 && (
            <Row className="hstack justify-content-center mt-3 mb-4">
              <Col xs={3} className="mt-4">
                <hr />
              </Col>
            </Row>
          )}

          {recosLinkedToProj.length > 0 &&
            recosLinkedToProj.map((reco, index) => (
              <Row className="mt-2 mb-3" key={`row-recos-${index}`}>
                <Col xs={12} lg={{ span: 10, offset: 1 }}>
                  {/* <h1
                    id="title-comment"
                    className={`title-anim huge-text-4 text-colored-secondary-letter text-center ${
                      elemsAnim &&
                      elemsAnim.length > 0 &&
                      elemsAnim[0] &&
                      typeof elemsAnim[0][0] === 'string'
                        ? elemsAnim[0][0]
                        : 'opacity-0'
                    }`}
                  >
                    Commentaire lié au <span>projet</span>
                  </h1> */}
                  <Recommendation
                    key={reco.id}
                    logo={reco.logo}
                    comment={reco.comment}
                    contributor={reco.contributor}
                    job={reco.job}
                    picture={reco.picture}
                    id={reco.id}
                    company={reco.company}
                    index={index}
                    realisationLinkedName={reco.realisationLinkedName}
                  />
                </Col>
              </Row>
            ))}

          {projSameCateg.length > 0 ? (
            <>
              <Row className="hstack justify-content-center mt-0 mt-5 mb-4">
                <Col xs={3}>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={{ span: 10, offset: 1 }}>
                  <h1
                    className={`huge-text-4 text-colored-secondary-letter text-center`}
                  >
                    Dans la même <span>catégorie</span>
                  </h1>
                </Col>
              </Row>

              <Row>
                <Col xs={12} lg={{ span: 10, offset: 1 }}>
                  <Row className="g-5">
                    {projSameCateg.map((proj, index) =>
                      index < 2 ? (
                        <Col
                          xs={12}
                          lg={6}
                          key={`related-realisation-${proj.id}`}
                        >
                          <Realisation
                            id={proj.id}
                            index={index}
                            name={proj.name}
                            category={proj.category}
                            description={proj.description}
                            author={proj.author}
                            cover={proj.cover}
                            gallery={proj.gallery}
                            company={proj.company}
                            year={proj.year}
                            logo={proj.logo}
                            logoWhite={proj.logoWhite}
                            layerColor={proj.layerColor}
                            techno={proj.techno}
                            link={proj.link}
                            position={proj.position}
                            positionArrToAnim={positionArrToAnim}
                            realisationsPage={realisationsPage}
                            setPositionArrToAnim={setPositionArrToAnim}
                          />
                        </Col>
                      ) : null
                    )}
                  </Row>
                </Col>
              </Row>
            </>
          ) : null}

          <Row className="hstack justify-content-center my-5">
            <Col xs={12} md={6} className="my-3 d-flex justify-content-center">
              <Link to={`/realisations`} className={`dgt-btn dgt-cta`}>
                Voir toutes les réalisations
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
      <Modal
        dialogClassName="modal-realisation"
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {realisation.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            pause={false}
            interval={null}
            controls={true}
            className="realisation-page-carousel"
          >
            {realisation.gallery.map((picture, index) => (
              <Carousel.Item key={`modal-${index}`}>
                <img
                  className="d-block w-100"
                  src={picture}
                  alt={`${realisation.name}-${index}`}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>
    </>
  ) : (
    <Routes>
      <Route path="*" element={<Error />} />
    </Routes>
  )
}
export default RealisationPage
