// REACT COMPONENTS

import React, { useEffect } from 'react'

// META TAGS

import { Helmet } from 'react-helmet-async'

// REACT ROUTER

import { useLocation } from 'react-router-dom'

// HOOKS

import { useAnimAppear, useTheme } from '../../utils/hooks'

// REACT BOOTSTRAP

import { Col, Container, Row, Image, Badge } from 'react-bootstrap'

// ASSETS

import { customers } from '../../assets/datas/customers/index.jsx'

// ASSETS LOGOS COMPANIES OG

import gaultmillau from '../../assets/img/logos/companies/original/gaultmillau.svg'
import aufeminin from '../../assets/img/logos/companies/original/aufeminin.png'
import marmiton from '../../assets/img/logos/companies/original/marmiton.png'
import etoilecasting from '../../assets/img/logos/companies/original/etoile-casting.png'

// ASSETS LOGOS COMPANIES WHITE

import gaultmillauWhite from '../../assets/img/logos/companies/white/gaultmillau.svg'
import etoilecastingWhite from '../../assets/img/logos/companies/white/etoile-casting.png'
import ecomundoWhite from '../../assets/img/logos/companies/white/ecomundo.png'

// IMAGES

import aProposThumb from '../../assets/img/about/a-propos-thumb.jpg'

function About() {
  const { theme } = useTheme()
  let location = useLocation()

  const { setParamsAnim, setCountAnimation } = useAnimAppear()

  useEffect(() => {
    // Because of anim context provider, this below is mandatory for preventing errors from hook
    setParamsAnim([[[[], '', 0, '']], location.pathname])
    setCountAnimation(true)
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>À propos</title>
        <meta property="og:title" content="À propos" />
        <meta
          property="og:description"
          content="Expériences emploi et freelance"
        />
        <meta name="description" content="À propos" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta
          property="og:image:secure_url"
          content={`https://www.deegity.com'${aProposThumb}`}
        />
        <meta property="og:url" content={`https://www.deegity.com/a-propos`} />
      </Helmet>

      <Container fluid className="p-0 d-none d-md-block">
        <div className="parallax-dgt portrait"></div>
      </Container>
      <Container
        fluid
        className={`${theme === 'light' ? 'bg-lightGray-no-top' : ''}`}
      >
        <Container>
          <Row className="position-relative">
            <Col className="overflow-scroll">
              <section>
                <h3 className="text-center huge-text-3 title-anim text-colored-secondary-letter">
                  Expériences <span>Freelance</span>
                </h3>
                <Row xs={1} sm={2} md={3} lg={4} className="g-5">
                  {customers.map(
                    (
                      {
                        name,
                        description,
                        logo,
                        logoWhite,
                        link,
                        category,
                        tags
                      },
                      index
                    ) => (
                      <Col
                        key={index}
                        className="rounded-3 position-relative d-flex justify-content-evenly flex-column"
                      >
                        <a
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          className="about d-block stretched-link mb-4"
                        >
                          {logoWhite !== null ? (
                            <Image
                              fluid
                              className="d-block m-auto"
                              src={theme === 'light' ? logo : logoWhite}
                            ></Image>
                          ) : (
                            <Image
                              src={logo}
                              className="d-block m-auto"
                            ></Image>
                          )}
                        </a>
                        <section className="mx-auto">
                          {tags.map((tag, index) => (
                            <Badge
                              key={index}
                              pill
                              bg="light"
                              text="dark"
                              className="p-2 mt-2 d-inline-block me-2 fw-light"
                            >
                              {tag}
                            </Badge>
                          ))}
                        </section>
                      </Col>
                    )
                  )}
                </Row>
              </section>
              <section className="pb-5">
                <h3 className="text-center huge-text-3 title-anim text-colored-secondary-letter">
                  Expériences <span>emploi</span>
                </h3>
                <Row>
                  <Col
                    xs={12}
                    sm={6}
                    md={4}
                    className="d-flex d-sm-block justify-content-center justify-content-sm-start mb-5"
                  >
                    <article className="d-flex align-items-center flex-column">
                      <h4>Infographiste</h4>
                      <p className="fw-italic">2011 - 2012</p>
                      <div className="about">
                        <Image
                          fluid
                          className="d-block p-2 bg-black"
                          src={ecomundoWhite}
                        ></Image>
                      </div>
                    </article>
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    md={4}
                    className="d-flex d-sm-block justify-content-center justify-content-sm-start mb-5"
                  >
                    <article className="d-flex align-items-center flex-column">
                      <h4>Webdesigner</h4>
                      <p className="fw-italic"> 2013 - 2015</p>
                      <div className="about">
                        <Image
                          fluid
                          className={`d-block mb-2 ${
                            theme === 'dark' && 'bg-white p-2'
                          }`}
                          src={aufeminin}
                        ></Image>
                      </div>
                      <div className="about">
                        <Image
                          fluid
                          className={`d-block mb-2 ${
                            theme === 'dark' && 'bg-white p-2'
                          }`}
                          src={marmiton}
                        ></Image>
                      </div>
                      <div className="about">
                        <Image
                          fluid
                          className="d-block mb-2"
                          src={
                            theme === 'light'
                              ? etoilecasting
                              : etoilecastingWhite
                          }
                        ></Image>
                      </div>
                    </article>
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    md={4}
                    className="d-flex d-sm-block justify-content-center justify-content-sm-start mb-5"
                  >
                    <article className="d-flex align-items-center flex-column">
                      <h4>Responsable interface utilisateur</h4>
                      <p className="fw-italic">2016 -2021</p>
                      <div className="about">
                        <Image
                          fluid
                          className="d-block mb-2 w-100"
                          src={
                            theme === 'light' ? gaultmillau : gaultmillauWhite
                          }
                        ></Image>
                      </div>
                    </article>
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  )
}
export default About
