// REACT COMPONENTS

import React, { useEffect } from 'react'

// META TAGS

import { Helmet } from 'react-helmet-async'

// REACT ROUTER

import { useParams, useLocation } from 'react-router-dom'

// HOOKS

import { useAnimAppear, useTheme } from '../../utils/hooks'

// BOOTSTRAP COMPONENTS

import { Container, Row, Col } from 'react-bootstrap'

// DATAS

import Soon from '../../components/soon'

import creation from '../../assets/img/skills/creation-site-thumb.jpg'
import dev from '../../assets/img/skills/developpement-thumb.jpg'
import update from '../../assets/img/skills/mise-a-jour-thumb.jpg'
import webdesign from '../../assets/img/skills/webdesign-thumb.jpg'
import print from '../../assets/img/skills/print-thumb.jpg'

function ServicePage() {
  const { theme } = useTheme()
  const { id } = useParams()
  let serviceStr

  const service = (id) => {
    switch (id) {
      case 'webdesign':
        serviceStr = 'Web Design'
        break
      case 'print':
        serviceStr = 'Print'
        break
      case 'creation-site':
        serviceStr = 'Création de site'
        break
      case 'mise-a-jour':
        serviceStr = 'Mise à jour'
        break
      case 'developpement':
        serviceStr = 'Développement'
        break
      default:
        serviceStr = 'Web Design'
    }
  }

  service(id)

  const { setParamsAnim, setCountAnimation } = useAnimAppear()

  let location = useLocation()

  useEffect(() => {
    // Because of anim context provider, this below is mandatory for preventing errors from hook
    setParamsAnim([[[[], '', 0, '']], location.pathname])
    setCountAnimation(true)
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>{`Service : ${service}`}</title>
        <meta property="og:title" content={`Service : ${service}`} />
        <meta
          property="og:description"
          content={`Découvrez notre service ${service}`}
        />
        <meta
          name="description"
          content={`Découvrez notre service ${service}`}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta
          property="og:image:secure_url"
          content={`https://www.deegity.com${
            service === 'creation-site'
              ? creation
              : service === 'mise-a-jour'
              ? update
              : service === 'developpement'
              ? dev
              : service === 'webdesign'
              ? webdesign
              : service === 'webdesign'
              ? print
              : null
          }`}
        />
        <meta
          property="og:url"
          content={`https://www.deegity.com/service/${service}`}
        />
      </Helmet>
      <Container
        fluid
        className={` ${theme === 'light' ? 'bg-lightGray-no-top' : ''}`}
      >
        <Row className="d-flex justify-content-center">
          <Col xs={12} md={6}>
            <Soon />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ServicePage
