//REACT COMPONENTS

import React, { useEffect, useRef } from 'react'

// META TAGS

import { Helmet } from 'react-helmet-async'

// REACT ROUTER

import { useLocation } from 'react-router-dom'

// HOOKS

import { useRealisations, useAnimAppear, useTheme } from '../../utils/hooks'

// DATAS

import { realisationsList } from '../../assets/datas/realisations'

// BOOTSTRAP COMPONENTS

import { Row, Col, Container } from 'react-bootstrap'

// MY COMPONENTS

import Realisation from '../../components/realisation'
import Filters from '../../components/filters'

// IMAGES

import deegityThumb from '../../assets/img/logos/deegity/deegity-thumb.jpg'

function RealisationsPage() {
  const { theme } = useTheme()
  let location = useLocation()

  const { setParamsAnim, setCountAnimation } = useAnimAppear()

  const {
    realisations,
    updateRealisationList,
    filteredCategories,
    filteredCompanies,
    filteredYears,
    checkedYears,
    checkedCategories,
    checkedCompanies,
    setCheckedCategories,
    setCheckedCompanies,
    setCheckedYears,
    disabledCompanies,
    disabledYears,
    disabledCategories,
    setDisabledCompanies,
    setDisabledYears,
    setDisabledCategories,
    authors,
    years,
    companies,
    categories,
    selectedFilters,
    updateSelectedFilters,
    realisationsFiltered,
    updateRealisationsFiltered
  } = useRealisations()

  // // WHEN PAGE DISPLAY FIRST, DISPLAY ALL REALISATIONS

  const realisationsSorted = useRef()

  useEffect(() => {
    updateSelectedFilters([])
    realisationsSorted.current = realisationsList.sort((a, b) => {
      return b.year - a.year
    })
    updateRealisationList(realisationsSorted.current)

    /////////////////////////////////////////

    const windowHeight = window.innerHeight
    const columnFilter = document.getElementsByClassName('sticky-top')[0]
    const containerHeader =
      document.getElementsByClassName('container-header')[0].offsetHeight
    columnFilter.style.height = windowHeight - containerHeader + 'px'

    // Because of anim context provider, this below is mandatory for preventing errors from hook
    setParamsAnim([[[[], '', 0, '']], location.pathname])
    setCountAnimation(true)
    window.scrollTo(0, 0)
  }, [])

  // IF NO FILTERS ARE CHECKED, DISPLAY EVERY REALISATIONS

  useEffect(() => {
    if (selectedFilters.length === 0) {
      updateRealisationsFiltered([])
      updateRealisationList(realisationsSorted.current)
    }
  }, [selectedFilters])

  // UNCHECK EVERY FILTERS AND RELOAD INITIAL REALISATION LIST
  const resetAll = () => {
    setCheckedCategories(categories.fill(false, 0))
    setCheckedYears(years.fill(false, 0))
    setCheckedCompanies(companies.fill(false, 0))
    updateRealisationList(realisationsSorted.current)
    setDisabledCategories(authors.fill(false, 0))
    setDisabledCompanies(authors.fill(false, 0))
    setDisabledYears(authors.fill(false, 0))
    updateSelectedFilters([])
    updateRealisationsFiltered([])
  }

  return (
    <>
      <Helmet>
        <title>Toutes les réalisations de Deegity</title>
        <meta
          property="og:title"
          content="Toutes les réalisations de Deegity"
        />
        <meta
          property="og:description"
          content="Naviguez et découvrez toutes les réalisations de Deegity"
        />
        <meta
          name="description"
          content="Naviguez et découvrez toutes les réalisations de Deegity"
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta
          property="og:image:secure_url"
          content={`https://www.deegity.com${deegityThumb}`}
        />
        <meta
          property="og:url"
          content={`https://www.deegity.com/realisations`}
        />
      </Helmet>
      <Container
        fluid
        className={`pb-5 ${theme === 'light' ? 'bg-lightGray-no-top' : ''}`}
      >
        <Container>
          <Row>
            <Col className="animate__animated animate__flipInX">
              <h1 className="huge-text-4 text-colored-secondary-letter mb-4">
                Toutes les réalisations
              </h1>
              <p className="d-none d-md-block fw-light mb-5 fs-4">
                👇 Filtrez par ici
              </p>
            </Col>
          </Row>
          <Row className="mb-5 pb-5">
            <Col md={3} lg={2} className="pe-5 d-none d-md-block">
              <Row className="sticky-top pt-4 overflow-scroll">
                <Col className="filters-wrapper">
                  {categories.length > 1 && (
                    <>
                      <h4 className="mt-0">Catégories</h4>
                      <Filters
                        realisations={realisations}
                        updateRealisationList={updateRealisationList}
                        id="cat"
                        keyFilter="category"
                        filter={filteredCategories}
                        checked={checkedCategories}
                        setChecked={setCheckedCategories}
                        disabled={disabledCategories}
                        realisationsFiltered={realisationsFiltered}
                        updateRealisationsFiltered={updateRealisationsFiltered}
                      />
                    </>
                  )}
                  {years.length > 1 && (
                    <>
                      <h4>Années</h4>
                      <Filters
                        realisations={realisations}
                        updateRealisationList={updateRealisationList}
                        id="years"
                        keyFilter="year"
                        filter={filteredYears}
                        checked={checkedYears}
                        setChecked={setCheckedYears}
                        disabled={disabledYears}
                        realisationsFiltered={realisationsFiltered}
                        updateRealisationsFiltered={updateRealisationsFiltered}
                      />
                    </>
                  )}
                  {companies.length > 1 && (
                    <>
                      <h4>Clients</h4>
                      <Filters
                        realisations={realisations}
                        updateRealisationList={updateRealisationList}
                        id="companies"
                        keyFilter="company"
                        filter={filteredCompanies}
                        checked={checkedCompanies}
                        setChecked={setCheckedCompanies}
                        disabled={disabledCompanies}
                        realisationsFiltered={realisationsFiltered}
                        updateRealisationsFiltered={updateRealisationsFiltered}
                      />
                    </>
                  )}
                  <button
                    className="dgt-btn dgt-cta w-100 mt-4"
                    type="button"
                    onClick={resetAll}
                  >
                    Réinitialiser
                  </button>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={9} lg={10} className="px-4 px-md-0">
              <Row className="g-5">
                {realisations.map(
                  (
                    {
                      id,
                      name,
                      category,
                      description,
                      author,
                      cover,
                      gallery,
                      company,
                      year,
                      logo,
                      logoWhite,
                      layerColor,
                      techno,
                      position
                    },
                    index
                  ) => (
                    <Col key={`col-${index}`} xs={12} md={6} lg={4} xl={3}>
                      <Realisation
                        id={id}
                        index={index}
                        name={name}
                        category={category}
                        description={description}
                        author={author}
                        cover={cover}
                        gallery={gallery}
                        company={company}
                        year={year}
                        logo={logo}
                        logoWhite={logoWhite}
                        layerColor={layerColor}
                        techno={techno}
                        position={position}
                      />
                    </Col>
                  )
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  )
}

export default RealisationsPage
