// REACT

import React from 'react'

// REACT ROUTER DOM

import { Link } from 'react-router-dom'

// HOOKS

import { useTheme } from '../../../utils/hooks'

// BOOTSTRAP

import { Image } from 'react-bootstrap'

// ASSETS

import LightLogo from '../../../assets/img/logos/deegity/logo-deegity.svg'
import DarkLogo from '../../../assets/img/logos/deegity/logo-deegity.svg'

function Logo() {
  const { theme } = useTheme()
  return (
    <Link to="/" className="d-flex d-block text-decoration-none logo-dgt">
      <Image
        src={theme === 'light' ? DarkLogo : LightLogo}
        className="m-auto float-start"
      />
      <h1
        id="logo-deegity"
        className="animate__animated animate__flipInX animate__delay-1s ms-2 d-inline-block fs-3 fw-bolder float-start text-decoration-none text-center text-white no-gradient"
      >
        Deegity
      </h1>
    </Link>
  )
}
export default Logo
