// REACT COMPONENTS

import React, { useEffect, useRef, useState } from 'react'

// REACT ROUTER

import { Link, useLocation } from 'react-router-dom'

// REACT BOOTSTRAP

import { Card, Image } from 'react-bootstrap'

// PERSONAL HOOKS

import { useAnimAppear, useTheme } from '../../utils/hooks'

function Realisation({
  id,
  name,
  category,
  description,
  author,
  cover,
  gallery,
  company,
  year,
  logo,
  logoWhite,
  layerColor,
  techno,
  link,
  index,
  position,
  realisationsPage,
  positionArrToAnim,
  setPositionArrToAnim
}) {
  const companyNoSpeCarac = company
    .replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g, '-')
    .toLowerCase()
  const nameNoSpeCarac = name
    .replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g, '-')
    .toLowerCase()
  const companyUrlFormat = companyNoSpeCarac
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
  const nameUrlFormat = nameNoSpeCarac
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

  const overlay = {
    backgroundColor: layerColor
  }
  // let descTruncated

  // const truncate = (max) => {
  //   if (description && description.length > max) {
  //     descTruncated = description.slice(0, max) + '...'
  //   }
  // }
  // truncate(100)
  let location = useLocation()
  const { theme } = useTheme()
  const { elemsAnim } = useAnimAppear()

  const [value, setValue] = useState(0) // integer state

  const lastTimeAnim = useRef(null)
  const counterAnim = useRef(0)

  const forceUpdate = (time) => {
    // update state to force render
    // An function that increment the previous state like here
    // is better than directly setting `value + 1`

    if (lastTimeAnim.current !== null) {
      const delta = time - lastTimeAnim.current
      counterAnim.current += delta * 10
      if (counterAnim.current > 10000) {
        setValue((value) => value + 1)
        counterAnim.current = 0
      }
    }
    lastTimeAnim.current = time
    window.requestAnimationFrame(forceUpdate)
  }
  if (location.pathname !== '/realisations') {
    window.requestAnimationFrame(forceUpdate)
  }

  useEffect(() => {
    return () => window.cancelAnimationFrame(forceUpdate)
  }, [value])

  return (
    <>
      <div
        className={`position-relative ${
          elemsAnim &&
          elemsAnim.length > 0 &&
          elemsAnim[positionArrToAnim] &&
          typeof elemsAnim[positionArrToAnim][index] === 'string' &&
          (location.pathname === '/' || realisationsPage)
            ? elemsAnim[positionArrToAnim][index]
            : location.pathname === '/realisations'
            ? 'animate__animated animate__fadeInRight'
            : 'opacity-0'
        }`}
      >
        <Card
          id={`realisation-${index}`}
          className="border border-0 bg-transparent rounded-3"
        >
          <Link
            to={`/realisation/${companyUrlFormat}/${nameUrlFormat}/${position}`}
            className="text-decoration-none d-block card-dgt rounded-3"
          >
            <section className="position-relative ">
              <Card.Img
                variant="top"
                className={`img-cover size-img-4 rounded-3 ${
                  theme === 'light'
                    ? 'shadow'
                    : 'shadow-dgt-light bg-transparent'
                }`}
                src={cover}
              />
              <Image
                src={logoWhite ? logoWhite : logo}
                className="position-absolute top-50 start-50 translate-middle w-50 bg-z-index"
              ></Image>
              <div
                className="position-absolute overlay-colored w-100 h-100 top-0 bottom-0 rounded-3"
                style={overlay}
              ></div>
            </section>
          </Link>
        </Card>
        <p className="mt-3 mb-1 text-uppercase fw-light">{category}</p>
        <h5 className="my-0">{name}</h5>
      </div>
    </>
  )
}
export default Realisation
