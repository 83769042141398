// REACT COMPONENTS

import React, { useEffect, useState, useRef } from 'react'

// META TAGS

import { Helmet } from 'react-helmet-async'

// REACT ROUTER

import { useLocation, Link } from 'react-router-dom'

// HOOKS

import { useAnimAppear, useTheme, useScrollDirection } from '../../utils/hooks'

// BOOTSTRAP COMPONENTS

import { Container, Row, Col } from 'react-bootstrap'

// MY COMPONENTS

import JobAnimation from '../../components/jobAnimation'
import Realisation from '../../components/realisation'
import Skills from '../../components/skills'
import Offers from '../../components/offers'
import Recommendations from '../../components/recommendations'
import Companies from '../../components/companies'

// DATAS

import { realisationsList } from '../../assets/datas/realisations'
import { pros } from '../../assets/datas/homepage/pros'

// IMAGES

import deegityThumb from '../../assets/img/logos/deegity/deegity-thumb.jpg'

function Homepage() {
  let location = useLocation()
  const heightPage = useRef()
  const heightFooter = useRef()
  const heightReco = useRef()
  const reachedBottom = useRef(false)

  const { theme } = useTheme()
  const { scrollY } = useScrollDirection()
  const {
    elemsAnim,
    setParamsAnim,
    paramsAnim,
    setCountAnimation,
    animTarget
  } = useAnimAppear()

  const [pro, setPro] = useState(pros[14])

  const newArrProj = useRef(
    realisationsList.filter((proj, index) => {
      return proj.id === 'web-nicolasbesson-1' || proj.id === 'web-gcbc-1'
    })
  )

  const [positionArrToAnim, setPositionArrToAnim] = useState()

  useEffect(() => {
    const titlesElements = document.querySelectorAll('[id^=title-')
    const realisationsElements = document.querySelectorAll(
      '[id^="realisation"]'
    )
    const skillsElements = document.querySelectorAll('[id^="skill"]')
    const offersElements = document.querySelectorAll('[id^="offer"]')

    // FIRST PARAM = the array of blocks to anim

    // 1 - arr of elems to animate
    // 2 - anim classes names
    // 3 - threshold for launching animations
    // 4 - block name to anim (useful for shared components like realisation component

    // SECOND PARAM = the location pathname

    setParamsAnim([
      [
        [
          titlesElements,
          'animate__animated animate__flipInX',
          1100,
          'titlesHomeAnim'
        ],
        [
          realisationsElements,
          'animate__animated animate__fadeInRight',
          900,
          'realisationsHomeAnim'
        ],
        [
          skillsElements,
          'animate__animated animate__fadeInUp',
          900,
          'skillsHomeAnim'
        ],
        [
          offersElements,
          'animate__animated animate__zoomInUp',
          900,
          'offersHomeAnim'
        ]
      ],
      location.pathname
    ])
    setCountAnimation(true)
    window.requestAnimationFrame(forceUpdate)
    window.scrollTo(0, 0)

    heightPage.current = document.body.offsetHeight
    heightReco.current = document.getElementById('recommendations').offsetHeight
    heightFooter.current =
      document.getElementsByTagName('footer')[0].offsetHeight
  }, [])

  useEffect(() => {
    const windowHeight = window.innerHeight
    const header = document.getElementsByClassName('container-header')[0]
    const headerHeight = header.offsetHeight
    const jumbotron = document.getElementById('bg-height')
    jumbotron.style.height = windowHeight - headerHeight - 15 + 'px'
  }, [pro])

  useEffect(() => {
    if (animTarget.length > 0) {
      if (location.pathname === '/') {
        setPositionArrToAnim(animTarget.indexOf('realisationsHomeAnim'))
      }
    }
  }, [animTarget, paramsAnim])

  const [value, setValue] = useState(0) // integer state

  const lastTimeAnim = useRef(null)
  const counterAnim = useRef(0)

  const forceUpdate = (time) => {
    // update state to force render
    // An function that increment the previous state like here
    // is better than directly setting `value + 1`
    if (lastTimeAnim.current !== null) {
      if (
        scrollY.current <
        heightPage.current - heightFooter.current - heightReco.current
      ) {
        reachedBottom.current = false
        const delta = time - lastTimeAnim.current
        counterAnim.current += delta * 5
        if (counterAnim.current > 10000) {
          setValue((value) => value + 1)
          counterAnim.current = 0
        }
      } else {
        return (reachedBottom.current = true)
      }
    }
    lastTimeAnim.current = time
    window.requestAnimationFrame(forceUpdate)
  }

  useEffect(() => {
    if (scrollY.current < 300 && reachedBottom.current) {
      window.requestAnimationFrame(forceUpdate)
    } else return
  }, [scrollY.current])

  useEffect(() => {
    return () => window.cancelAnimationFrame(forceUpdate)
  }, [value])

  return (
    <>
      <Helmet>
        <title>Création de sites web et de supports print</title>
        <meta
          name="description"
          content="Création de sites web et de supports print"
        />
        <meta property="og:title" content="Page d'accueil de Deegity" />
        <meta
          property="og:description"
          content="Création de sites web et de supports print"
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta
          property="og:image:secure_url"
          content={`https://www.deegity.com${deegityThumb}`}
        />
        <meta property="og:url" content={`https://www.deegity.com`} />
      </Helmet>
      <Container
        fluid
        className={`jumbotron-bg px-0 ${
          theme === 'light' ? 'bg-primary-dgt' : ''
        }`}
      >
        <Container fluid className="pt-3 bg-waves">
          <Container className="px-0">
            <Row
              id="bg-height"
              className="justify-content-center align-items-stretch"
            >
              <Col xs={12} className="d-flex d-lg-block align-items-stretch">
                <JobAnimation pro={pro} setPro={setPro} />
              </Col>
            </Row>
          </Container>
        </Container>
      </Container>

      <Container
        fluid
        className={`pb-5 ${
          theme === 'light' ? 'bg-lightGray' : 'bg-pattern-dark-mode'
        }`}
      >
        <Container>
          <Row id="new-realisations">
            <Col>
              <h1
                id="title-realisation"
                className={`title-anim huge-text-4 text-colored-secondary-letter text-center ${
                  elemsAnim &&
                  elemsAnim.length > 0 &&
                  elemsAnim[0] &&
                  typeof elemsAnim[0][0] === 'string'
                    ? elemsAnim[0][0]
                    : 'opacity-0'
                }`}
              >
                Fraîchement
                <span>débarqué</span>
              </h1>
            </Col>
          </Row>

          <Row xs={1} md={2} className={`g-3 g-sm-5 realisations mb-5 pb-5`}>
            {newArrProj.current.map(
              (
                {
                  id,
                  name,
                  category,
                  description,
                  author,
                  cover,
                  gallery,
                  company,
                  year,
                  logo,
                  logoWhite,
                  layerColor,
                  techno,
                  link,
                  position
                },
                index
              ) => (
                <Col key={`col-${index}`} className="pt-3">
                  <Realisation
                    id={id}
                    index={index}
                    name={name}
                    category={category}
                    description={description}
                    author={author}
                    cover={cover}
                    gallery={gallery}
                    company={company}
                    year={year}
                    logo={logo}
                    logoWhite={logoWhite}
                    layerColor={layerColor}
                    techno={techno}
                    link={link}
                    position={position}
                    positionArrToAnim={positionArrToAnim}
                  />
                </Col>
              )
            )}
          </Row>

          {/* <Row xs={1} xxl={2} className={`gx-5 overflow-scroll realisations`}></Row> */}
        </Container>
      </Container>
      <Container
        fluid
        className={`px-sm-5 ${
          theme === 'light' ? 'bg-theme-light' : 'bg-pattern-dark-mode'
        }`}
      >
        <Container>
          <Row>
            <Col>
              <h1
                id="title-skills"
                className={`title-anim huge-text-4 text-colored-secondary-letter text-center 

                ${
                  elemsAnim &&
                  elemsAnim.length > 0 &&
                  elemsAnim[0] &&
                  typeof elemsAnim[0][1] === 'string'
                    ? elemsAnim[0][1]
                    : 'opacity-0'
                }`}
              >
                Les <span>Services</span>
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Skills />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        fluid
        className={`pb-sm-5 px-sm-5 ${
          theme === 'light'
            ? 'bg-lightGray no-bg-border-bottom-dgt-down-md'
            : 'bg-pattern-dark-mode'
        }`}
      >
        <Container>
          <Row>
            <Col>
              <header className="text-center">
                <h1
                  id="title-offers"
                  className={`title-anim huge-text-4 text-colored-secondary-letter mb-3
                  ${
                    elemsAnim &&
                    elemsAnim.length > 0 &&
                    elemsAnim[0] &&
                    typeof elemsAnim[0][2] === 'string'
                      ? elemsAnim[0][2]
                      : 'opacity-0'
                  }`}
                >
                  Prêt à démarrer avec
                  <span>Deegity ?</span>
                </h1>
                <p
                  id="title-offers-sub"
                  className={`title-anim fw-light mb-0 mb-sm-5 fs-4 ${
                    elemsAnim &&
                    elemsAnim.length > 0 &&
                    elemsAnim[0] &&
                    typeof elemsAnim[0][3] === 'string'
                      ? elemsAnim[0][3]
                      : 'opacity-0'
                  }`}
                >
                  Choisissez ci-dessous l'offre la plus adaptée à votre besoin
                </p>
              </header>
            </Col>
          </Row>
          <Row className="gx-5 mt-sm-4">
            <Offers />
          </Row>
          <div className="py-5 d-flex justify-content-center">
            <Link to="/offres" className={`dgt-btn dgt-cta`}>
              En savoir plus sur nos offres
            </Link>
          </div>
        </Container>
      </Container>

      <Container
        fluid
        className={`pb-5 d-none d-md-block ${
          theme === 'light' ? 'bg-primary-dgt' : ''
        }`}
      >
        <Row>
          <Col>
            <h1
              id="title-companies"
              className={`title-anim huge-text-4 text-colored-secondary-letter text-center 

                ${
                  elemsAnim &&
                  elemsAnim.length > 0 &&
                  elemsAnim[0] &&
                  typeof elemsAnim[0][4] === 'string'
                    ? elemsAnim[0][4]
                    : 'opacity-0'
                }`}
            >
              Nos
              <span>clients</span>
            </h1>
          </Col>
        </Row>
        <Companies className="py-5" />
      </Container>

      <Container
        fluid
        className={`py-5 ${
          theme === 'light'
            ? 'bg-lightGray no-bg-border-top-dgt-down-md'
            : 'bg-pattern-dark-mode'
        }`}
      >
        <Container>
          <Row>
            <Col>
              <h1
                id="title-reco"
                className={`title-anim huge-text-4 text-colored-secondary-letter text-center 
                ${
                  elemsAnim &&
                  elemsAnim.length > 0 &&
                  elemsAnim[0] &&
                  typeof elemsAnim[0][5] === 'string'
                    ? elemsAnim[0][5]
                    : 'opacity-0'
                }`}
              >
                Ils <span>aiment</span>
              </h1>
            </Col>
          </Row>
          <Row
            id="recommendations"
            className="pb-5 d-flex justify-content-center"
          >
            <Recommendations />
          </Row>
        </Container>
      </Container>
    </>
  )
}

export default Homepage
