// REACT COMPONENTS

import React, { useEffect, useState, useRef } from 'react'

// MAPBOX

import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax

// ASSETS

import deegityMarker from '../../assets/img/logos/deegity/marker.png'

// HOOKS

import { useTheme } from '../../utils/hooks'

function Maps() {
  const { theme } = useTheme()
  const locations = {
    Nice: [7.25, 43.7],
    Paris11Voltaire: [2.373, 48.861],
    Paris11Oberkampf: [2.3787649695313244, 48.86615710455293]
  }
  mapboxgl.accessToken =
    'pk.eyJ1IjoiZGVlZ2l0eSIsImEiOiJjbDA3Y2o3d24wNHVnM2NuNTYwYmNmdXRlIn0.SmWpwjyiOZuM4rHyax_ICw'
  const geojson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            locations.Paris11Oberkampf[0],
            locations.Paris11Oberkampf[1]
          ]
        },
        properties: {
          message: 'Kikou toi',
          title: 'Mapbox',
          description: 'Paris11Voltaire 11',
          iconSize: [75, 75]
        }
      }
    ]
  }
  const mapContainer = useRef(null)
  const map = useRef(null)
  // CHANGE LOCATION BELOW
  const [lng, setLng] = useState(locations.Paris11Oberkampf[0])
  const [lat, setLat] = useState(locations.Paris11Oberkampf[1])
  const [zoom, setZoom] = useState(12)
  const refTheme = useRef(theme)

  const renderMap = () => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: `mapbox://styles/mapbox/${
        theme === 'light' ? 'light-v10' : 'dark-v10'
      }`,
      center: [locations.Paris11Oberkampf[0], locations.Paris11Oberkampf[1]],
      zoom: zoom,
      interactive: false
    })
    map.current.addControl(new mapboxgl.NavigationControl(), 'top-right')

    // add markers to map
    for (const marker of geojson.features) {
      // create a HTML element for each feature
      const el = document.createElement('div')
      el.className = 'marker-dgt'
      const width = marker.properties.iconSize[0]
      const height = marker.properties.iconSize[1]

      el.className = 'marker-dgt'
      el.style.backgroundImage = `url(${deegityMarker})`
      el.style.width = `${width}px`
      el.style.height = `${height}px`
      el.style.backgroundSize = '100%'

      el.addEventListener('click', () => {
        window.location = 'mailto:contact@deegity.com?subject=Prise de contact'
      })

      // Add markers to the map.
      new mapboxgl.Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .addTo(map.current)
    }
  }

  useEffect(() => {
    if (map.current) return // initialize map only once
    renderMap()
  })

  useEffect(() => {
    renderMap()
  }, [theme])

  return <div ref={mapContainer} className="map-container"></div>
}

export default Maps
