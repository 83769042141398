// REACT COMPONENTS

import React from 'react'

// HOOKS

import { useTheme } from '../../../utils/hooks'

// BOOTSTRAP COMPONENTS

import { Nav, Form } from 'react-bootstrap'

// ITEMSMENU

import ItemsMenu from './itemsMenu'

function Menu() {
  const { theme, toggleTheme } = useTheme()

  const forceAnim = () => {
    document.getElementsByClassName('toggler-fixed')[0].checked = true
  }
  const closeAll = () => {
    document.getElementsByClassName('toggler')[0].checked = false
    document.getElementsByClassName('toggler-fixed')[0].checked = false
  }
  return (
    <div className="menu-wrap">
      <input type="checkbox" className="toggler" onClick={() => forceAnim()} />
      <div className="hamburger">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <input type="checkbox" className="toggler-fixed" />
      <div className="hamburger-fixed" onClick={() => closeAll()}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <Nav variant="pills" className="menu">
        <div>
          <div>
            <ul className="d-md-flex ps-0 mb-0 mt-md-2s mt-lg-0">
              <ItemsMenu />
            </ul>
          </div>
        </div>
        <Form.Switch
          className="animate__animated animate__flipInX animate__delay-1s switch-mode d-flex align-items-center d-none d-md-flex"
          onChange={() => toggleTheme()}
          id="theme"
          checked={theme === 'dark' ? true : false}
          label={theme === 'light' ? '🌙' : '🌞'}
        />
      </Nav>
    </div>
  )
}

export default Menu
