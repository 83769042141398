// REACT COMPONENTS

import React, { useEffect, useState } from 'react'

// REACT ROUTER

import { useLocation, Link } from 'react-router-dom'

// DATAS

import { skills } from '../../assets/datas/skills'

// HOOKS

import { useAnimAppear, useTheme } from '../../utils/hooks'

// REACT BOOTSTRAP

import { Row, Col, Image } from 'react-bootstrap'

function Skills() {
  const { theme } = useTheme()
  let location = useLocation()
  const { elemsAnim, animTarget } = useAnimAppear()

  const [positionArrToAnim, setPositionArrToAnim] = useState()

  useEffect(() => {
    if (animTarget.length > 0) {
      if (location.pathname === '/') {
        setPositionArrToAnim(animTarget.indexOf('skillsHomeAnim'))
      } else if (location.pathname === '/services') {
        setPositionArrToAnim(animTarget.indexOf('skillsPageServicesAnim'))
      }
    }
  }, [animTarget])

  return (
    <>
      {skills.map(({ id, name, picturePath, description }, index) => (
        <Row
          id={`skill-${id}`}
          key={`${id}`}
          className={`position-relative gx-5 d-flex align-items-center justify-content-center skill ${
            elemsAnim &&
            elemsAnim.length > 0 &&
            elemsAnim[positionArrToAnim] &&
            typeof elemsAnim[positionArrToAnim][index] === 'string'
              ? elemsAnim[positionArrToAnim][index]
              : 'opacity-0'
          }`}
        >
          <Col xs={12} md={{ span: 6, order: index % 1 === 0 ? 2 : 1 }}>
            <Image
              fluid
              src={picturePath}
              className={`py-5 ${
                theme === 'light' ? 'drop-shadow-light' : ''
              } ${index % 2 === 0 ? '' : ''}`}
            />
          </Col>
          <Col xs={12} md={{ span: 6, order: index % 2 === 0 ? 1 : 2 }}>
            <h2 className="text-colored-secondary-letter">{name}</h2>
            <p
              className="fw-light"
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
            <Link
              to={`/service/${id}`}
              className="dgt-btn dgt-cta d-inline-flex mt-4"
            >
              En savoir plus
            </Link>
          </Col>
        </Row>
      ))}
    </>
  )
}

export default Skills
