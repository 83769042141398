// REACT COMPONENTS

import React from 'react'

// MY COMPONENTS

import Logo from '../../components/header/logo'

// REACT BOOTSTRAP

import { Container, Row, Col } from 'react-bootstrap'
import Menu from './menu'

// HOOKS

import { useScrollDirection } from '../../utils/hooks'

function Header() {
  const { scrollDirection } = useScrollDirection()

  return (
    <header
      className={`py-2 position-sticky container-header pe-0 ${
        scrollDirection === 'up'
          ? 'go-up shadow-lg'
          : scrollDirection === 'down'
          ? 'go-down'
          : scrollDirection === 'top'
          ? 'stay-top'
          : ''
      }`}
    >
      <Container className="ps-lg-0">
        <Row className="justify-content-between justify-content-lg-between justify-content-md-center align-items-center">
          <Col xs="auto" className="ps-0">
            <Logo />
          </Col>
          <Col
            xs="auto"
            className="menu-handler float-end float-md-end-none p-0"
          >
            <Menu />
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default Header
