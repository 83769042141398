// LOGOS

import logoGcbc from '../../img/logos/customers/original/gcbc.png'
import logoNicolasbesson from '../../img/logos/customers/original/nicolas-besson.svg'

// PICTURES

import pictureGcbc from '../../img/recommendations/profile-picture-gcbc.png'
import pictureNicolasBesson from '../../img/recommendations/profile-picture-nicolasbesson.png'

export const recommendations = [
  // {
  //   logo: logoNicolasbesson,
  //   comment:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  //   contributor: 'Nicolas Besson',
  //   job: 'CEO',
  //   picture: pictureNicolasBesson,
  //   id: 'recommendation-1',
  //   company: 'Nicolas Besson',
  //   link: 'https://nicolasbesson.com',
  //   category: 'Mode',
  //   tags: ['Haute couture', 'Fashion'],
  //   realisationLinkedName: 'Site vitrine de Nicolas Besson'
  // },
  {
    logo: logoGcbc,
    comment:
      'Une belle collaboration, avec un professionnalisme et une disponibilité sans faille pour refondre de notre site web. Deegity a su nous écouter et répondre à nos besoins, tout en respectant le calendrier établi. Merci.',
    contributor: 'Loïc Héraud',
    job: 'Administrateur',
    picture: pictureGcbc,
    id: 'recommendation-2',
    company: 'GCBC UCPA',
    realisationLinkedName: 'Site GCBC UCPA'
  }
]
