// REACT LIBS

import React from 'react'

// REACT ROUTER

import { Routes, Route } from 'react-router-dom'

// COMPONENTS

import Layout from '../../components/layout'

// PAGES

import Homepage from '../../pages/homepage'
import About from '../../pages/about'
import Realisations from '../../pages/realisations'
import Realisation from '../../pages/realisation'
import Services from '../../pages/services'
import Service from '../../pages/service'
import Offers from '../../pages/offers'
import Contact from '../../pages/contact'
import Credits from '../../pages/credits'

// OTHER PAGES

import Error from '../error'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Homepage />} />
        <Route path="a-propos" element={<About />} />
        <Route path="realisations" element={<Realisations />} />
        <Route path="services" element={<Layout />}>
          <Route index element={<Services />} />
          <Route path="*" element={<Error />} />
        </Route>
        <Route path="offres" element={<Offers />} />
        <Route path="contact" element={<Contact />} />
        <Route path="credits" element={<Credits />} />
        <Route path="*" element={<Error />} />
      </Route>
      <Route path="/service/:id/*" element={<Layout />}>
        <Route path="/service/:id/*" element={<Service />} />
        <Route path="*" element={<Error />} />
      </Route>
      <Route
        path="/realisation/:companyUrlFormat/:nameUrlFormat/:position/*"
        element={<Layout />}
      >
        <Route
          path="/realisation/:companyUrlFormat/:nameUrlFormat/:position/*"
          element={<Realisation />}
        />
        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  )
}

export default App
