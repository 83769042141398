// REACT HOOKS

import { useEffect, useState, useRef, useContext } from 'react'

// REACT CONTEXT

import {
  AnimationsContext,
  RealisationsContext,
  ThemeContext
} from '../context'

export function useTheme() {
  const { theme, setTheme, toggleTheme } = useContext(ThemeContext)
  return { theme, setTheme, toggleTheme }
}

export function useAnimAppear() {
  const {
    arr,
    setArr,
    animName,
    setAnimName,
    threshold,
    setThreshold,
    elemsAnim,
    setElemsAnim,
    paramsAnim,
    setParamsAnim,
    animTarget,
    setAnimTarget,
    countAnimation,
    setCountAnimation
  } = useContext(AnimationsContext)
  return {
    arr,
    setArr,
    animName,
    setAnimName,
    threshold,
    setThreshold,
    elemsAnim,
    setElemsAnim,
    paramsAnim,
    setParamsAnim,
    animTarget,
    setAnimTarget,
    countAnimation,
    setCountAnimation
  }
}

export function useRealisations() {
  const {
    authors,
    years,
    companies,
    categories,
    filteredAuthors,
    filteredCategories,
    filteredCompanies,
    filteredYears,
    activeCat,
    activeYear,
    activeCompany,
    activeAuthor,
    setActiveAuthor,
    setActiveCat,
    setActiveCompany,
    setActiveYear,
    setAuthors,
    setCategories,
    setYears,
    setCompanies,
    checkedYears,
    checkedAuthors,
    checkedCategories,
    checkedCompanies,
    setCheckedAuthors,
    setCheckedCategories,
    setCheckedCompanies,
    setCheckedYears,
    realisations,
    updateRealisationList,
    selectedFilters,
    updateSelectedFilters,
    realisationsFiltered,
    updateRealisationsFiltered,
    setDisabledCompanies,
    setDisabledYears,
    setDisabledAuthors,
    setDisabledCategories,
    disabledCompanies,
    disabledYears,
    disabledAuthors,
    disabledCategories,
    filtering,
    setFiltering,
    otherCat,
    setOtherCat,
    addOrRemove,
    setAddOrRemove,
    lastCategSelected,
    setLastCategSelected,
    filterSelected,
    setFilterSelected,
    nextFiltersCateg,
    setNextFiltersCateg
  } = useContext(RealisationsContext)
  return {
    authors,
    years,
    companies,
    categories,
    filteredAuthors,
    filteredCategories,
    filteredCompanies,
    filteredYears,
    activeCat,
    activeYear,
    activeCompany,
    activeAuthor,
    setActiveAuthor,
    setActiveCat,
    setActiveCompany,
    setActiveYear,
    setAuthors,
    setCategories,
    setYears,
    setCompanies,
    checkedYears,
    checkedAuthors,
    checkedCategories,
    checkedCompanies,
    setCheckedAuthors,
    setCheckedCategories,
    setCheckedCompanies,
    setCheckedYears,
    realisations,
    updateRealisationList,
    selectedFilters,
    updateSelectedFilters,
    realisationsFiltered,
    updateRealisationsFiltered,
    setDisabledCompanies,
    setDisabledYears,
    setDisabledAuthors,
    setDisabledCategories,
    disabledCompanies,
    disabledYears,
    disabledAuthors,
    disabledCategories,
    filtering,
    setFiltering,
    otherCat,
    setOtherCat,
    addOrRemove,
    setAddOrRemove,
    lastCategSelected,
    setLastCategSelected,
    filterSelected,
    setFilterSelected,
    nextFiltersCateg,
    setNextFiltersCateg
  }
}

export const SCROLL_UP = 'up'
export const SCROLL_DOWN = 'down'
export const TOP = 'top'

export function useScrollDirection({
  initialDirection = TOP,
  thresholdPixels = 64
} = {}) {
  // maybe we can use a useref instead of usestate below ... ?
  const [scrollDirection, setScrollDirection] = useState(initialDirection)
  const scrollY = useRef(0)

  useEffect(() => {
    const threshold = thresholdPixels || 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      scrollY.current = window.pageYOffset

      if (scrollY.current <= 0) setScrollDirection(TOP)
      if (Math.abs(scrollY.current - lastScrollY) < threshold) {
        // We haven't exceeded the threshold
        ticking = false
        return
      }

      setScrollDirection(
        scrollY.current > lastScrollY ? SCROLL_DOWN : SCROLL_UP
      )
      lastScrollY = scrollY.current > 0 ? scrollY.current : 0

      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [initialDirection, scrollDirection, thresholdPixels])

  return { scrollDirection, scrollY }
}

export function useGoTop() {
  const preventGlitch = useRef(false)
  const [goTop, setGoTop] = useState(false)
  let windowHeight = window.innerHeight

  useEffect(() => {
    const updateGoTop = () => {
      if (preventGlitch.current) {
        return
      }
      let scrollY = window.pageYOffset
      scrollY < windowHeight - 300 ? setGoTop(false) : setGoTop(true)
    }

    const onScroll = () => {
      window.requestAnimationFrame(updateGoTop)
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return { goTop, setGoTop, preventGlitch }
}
