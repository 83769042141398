// REACT COMPONENTS

import React from 'react'

// REACT ROUTER

import { Outlet } from 'react-router-dom'

function Layout() {
  return <Outlet />
}
export default Layout
