// IMG PROJETS CLIENTS

// AUFEMININ / MARMITON

import aufemFacette from '../../img/realisations/aufeminin/web/infographies/facettes-vie-des-femmes.jpg'
import aufemParite from '../../img/realisations/aufeminin/web/infographies/parite-hommes-femmes.jpg'
import aufemTop10 from '../../img/realisations/aufeminin/web/infographies/top10-marques.jpg'
import aufemThumb from '../../img/realisations/aufeminin/web/infographies/infographies-thumb.jpg'

// CELERGEN

import celergenBrochureCover from '../../img/realisations/celergen/print/brochure/cover.jpg'
import celergenBrochure1 from '../../img/realisations/celergen/print/brochure/1.png'
import celergenBrochure2 from '../../img/realisations/celergen/print/brochure/2.png'
import celergenBrochure3 from '../../img/realisations/celergen/print/brochure/3.png'
import celergenBrochure4 from '../../img/realisations/celergen/print/brochure/4.png'
import celergenBrochure5 from '../../img/realisations/celergen/print/brochure/5.png'
import celergenBrochureThumb from '../../img/realisations/celergen/print/brochure/celergen-thumb.jpg'

// G&M PRINT

import gmHotelsCover from '../../img/realisations/gaultmillau/print/hotels-preferences/cover.jpg'
import gmHotels1 from '../../img/realisations/gaultmillau/print/hotels-preferences/1.jpg'
import gmHotels2 from '../../img/realisations/gaultmillau/print/hotels-preferences/2.jpg'
import gmHotelsThumb from '../../img/realisations/gaultmillau/print/hotels-preferences/hotels-thumb.jpg'

import gmGalaCover from '../../img/realisations/gaultmillau/print/menu-gala-2019/cover.jpg'
import gmGala1 from '../../img/realisations/gaultmillau/print/menu-gala-2019/1.jpg'
import gmGala2 from '../../img/realisations/gaultmillau/print/menu-gala-2019/2.jpg'
import gmGala3 from '../../img/realisations/gaultmillau/print/menu-gala-2019/3.jpg'
import gmGala4 from '../../img/realisations/gaultmillau/print/menu-gala-2019/4.jpg'
import gmGalaThumb from '../../img/realisations/gaultmillau/print/menu-gala-2019/gala-thumb.jpg'

import gmPresswall1 from '../../img/realisations/gaultmillau/print/presswall/1.jpg'
import gmPresswall2 from '../../img/realisations/gaultmillau/print/presswall/2.jpg'
import gmPresswallThumb from '../../img/realisations/gaultmillau/print/presswall/presswall-thumb.jpg'

// G&M WEB

import gmLetsgaultCover from '../../img/realisations/gaultmillau/web/letsgault/cover.jpg'
import gmLetsgault1 from '../../img/realisations/gaultmillau/web/letsgault/1.jpg'
import gmLetsgault2 from '../../img/realisations/gaultmillau/web/letsgault/2.jpg'
import gmLetsgaultThumb from '../../img/realisations/gaultmillau/web/letsgault/letsgault-thumb.jpg'

import gmMailingCover from '../../img/realisations/gaultmillau/web/mailings/cover.jpg'
import gmMailing1 from '../../img/realisations/gaultmillau/web/mailings/1.jpg'
import gmMailing2 from '../../img/realisations/gaultmillau/web/mailings/2.jpg'
import gmMailingThumb from '../../img/realisations/gaultmillau/web/mailings/mailings-thumb.jpg'

import gmChampagneCover from '../../img/realisations/gaultmillau/web/page-champagne/cover.jpg'
import gmChampagne from '../../img/realisations/gaultmillau/web/page-champagne/1.jpg'
import gmChampagneThumb from '../../img/realisations/gaultmillau/web/page-champagne/champagne-thumb.jpg'

import gmRedesignCover from '../../img/realisations/gaultmillau/web/redesign/cover.jpg'
import gmRedesign1 from '../../img/realisations/gaultmillau/web/redesign/1.jpg'
import gmRedesign2 from '../../img/realisations/gaultmillau/web/redesign/2.jpg'
import gmRedesignThumb from '../../img/realisations/gaultmillau/web/redesign/newsite-thumb.jpg'

import gmShopCover from '../../img/realisations/gaultmillau/web/shop/cover.jpg'
import gmShop1 from '../../img/realisations/gaultmillau/web/shop/1.jpg'
import gmShop2 from '../../img/realisations/gaultmillau/web/shop/2.jpg'
import gmShop3 from '../../img/realisations/gaultmillau/web/shop/3.jpg'
import gmShop4 from '../../img/realisations/gaultmillau/web/shop/4.jpg'
import gmShop5 from '../../img/realisations/gaultmillau/web/shop/5.jpg'
import gmShopThumb from '../../img/realisations/gaultmillau/web/shop/shop-thumb.jpg'

// GCBC

import gcbcSiteCover from '../../img/realisations/gcbc/web/cover.jpg'
import gcbcSite1 from '../../img/realisations/gcbc/web/1.jpg'
import gcbcSite2 from '../../img/realisations/gcbc/web/2.jpg'
import gcbcSite3 from '../../img/realisations/gcbc/web/3.jpg'
import gcbcSite4 from '../../img/realisations/gcbc/web/4.jpg'
import gcbcSite5 from '../../img/realisations/gcbc/web/5.jpg'
import gcbcSite6 from '../../img/realisations/gcbc/web/6.jpg'
import gcbcSiteThumb from '../../img/realisations/gcbc/web/gcbc-thumb.jpg'

// NICOLAS BESSON PROJET 1

import nicolasBessonSiteCover from '../../img/realisations/nicolasbesson/web/cover.jpg'
import nicolasBessonSite1 from '../../img/realisations/nicolasbesson/web/1.jpg'
import nicolasBessonSite2 from '../../img/realisations/nicolasbesson/web/2.jpg'
import nicolasBessonSite3 from '../../img/realisations/nicolasbesson/web/3.jpg'
import nicolasBessonSite4 from '../../img/realisations/nicolasbesson/web/4.jpg'
import nicolasBessonSiteThumb from '../../img/realisations/nicolasbesson/web/besson-thumb.jpg'

// PESSAC LEOGNAN

import pessacFlyer1 from '../../img/realisations/pessac-leognan/print/flyer/1.jpg'
import pessacFlyer2 from '../../img/realisations/pessac-leognan/print/flyer/2.jpg'
import pessacFlyerThumb from '../../img/realisations/pessac-leognan/print/flyer/pessac-thumb.jpg'

// LOGOS CLIENTS / COMPANIES ORIGINAL

import gaultmillau from '../../img/logos/companies/original/gaultmillau.svg'
import gcbc from '../../img/logos/customers/original/gcbc.png'
import nicolasbesson from '../../img/logos/customers/original/nicolas-besson.svg'
import pessac from '../../img/logos/customers/original/pessac-leognan.png'
import aufeminin from '../../img/logos/companies/original/aufeminin.png'
import celergen from '../../img/logos/customers/original/celergen.png'

// LOGOS CLIENTS / COMPANIES WHITE

import gaultmillauWhite from '../../img/logos/companies/white/gaultmillau.svg'

export const realisationsList = [
  {
    position: 0,
    name: 'Carton invitation 30 ans de Pessac Léognan',
    category: 'print',
    description: 'Carton invitation 30 ans de Pessac Léognan',
    author: 'Xavier Rain',
    id: 'print-pessac-1',
    cover: pessacFlyer1,
    gallery: [pessacFlyer1, pessacFlyer2],
    thumb: [pessacFlyerThumb],
    company: 'Pessac Léognan',
    year: '2017',
    logo: pessac,
    logoWhite: null,
    layerColor: '#131a24',
    techno: ['Photoshop', 'Illustrator'],
    link: null
  },
  {
    position: 1,
    name: 'Infographies',
    category: 'web',
    description: 'Diverses infographies',
    author: 'Xavier Rain',
    id: 'web-aufem-1',
    cover: aufemFacette,
    gallery: [aufemFacette, aufemParite, aufemTop10],
    thumb: [aufemThumb],
    company: 'aufeminin',
    year: '2014',
    logo: aufeminin,
    logoWhite: null,
    layerColor: '#e78cad',
    techno: ['Photoshop', 'Illustrator'],
    link: 'https://www.aufeminin.com'
  },
  {
    position: 2,
    name: 'Brochure',
    category: 'print',
    description: 'Brochure Celergen',
    author: 'Xavier Rain',
    id: 'print-celergen-1',
    cover: celergenBrochureCover,
    gallery: [
      celergenBrochure1,
      celergenBrochure2,
      celergenBrochure3,
      celergenBrochure4,
      celergenBrochure5
    ],
    thumb: [celergenBrochureThumb],
    company: 'Celergen',
    year: '2015',
    logo: celergen,
    logoWhite: null,
    layerColor: '#1f68da',
    techno: ['Photoshop', 'Illustrator'],
    link: 'http://celergenswiss.com/'
  },
  {
    position: 3,
    name: 'Livre Hôtels et Préférences',
    category: 'print',
    description:
      'Double page de présentation de la société Gault&Millau dans le livre Hôtels et Préférences',
    author: 'Xavier Rain',
    id: 'print-gm-1',
    cover: gmHotelsCover,
    gallery: [gmHotels1, gmHotels2],
    thumb: [gmHotelsThumb],
    company: 'Gault&Millau',
    year: '2020',
    logo: gaultmillau,
    logoWhite: gaultmillauWhite,
    layerColor: '#EA3C3A',
    techno: ['Photoshop', 'Illustrator', 'Indesign'],
    link: 'https://www.hotelspreference.com'
  },
  {
    position: 4,
    name: 'Menu de Gala 2019',
    category: 'print',
    description: 'Menu de gala annuel 2019 Gault&Millau',
    author: 'Xavier Rain',
    id: 'print-gm-2',
    cover: gmGalaCover,
    gallery: [gmGala1, gmGala2, gmGala3, gmGala4],
    thumb: [gmGalaThumb],
    company: 'Gault&Millau',
    year: '2019',
    logo: gaultmillau,
    logoWhite: gaultmillauWhite,
    layerColor: '#6e2dad',
    techno: ['Photoshop', 'Illustrator', 'Indesign'],
    link: 'https://fr.gaultmillau.com'
  },
  {
    position: 5,
    name: 'Presswall Gala annuel 2019',
    category: 'print',
    description: 'Presswall Gala annuel Gault&Millau 2019',
    author: 'Xavier Rain',
    id: 'print-gm-3',
    cover: gmPresswall1,
    gallery: [gmPresswall1, gmPresswall2],
    thumb: [gmPresswallThumb],
    company: 'Gault&Millau',
    year: '2019',
    logo: gaultmillau,
    logoWhite: gaultmillauWhite,
    layerColor: '#ed8a8b',
    techno: ['Photoshop', 'Illustrator', 'Indesign'],
    link: 'https://fr.gaultmillau.com'
  },
  {
    position: 6,
    name: "Site Let's Gault",
    category: 'web',
    description:
      'Minisite traitant une selection de restaurants Gault&Millau dans une dizaine de villes en France qui pratiquent la vente à emporter et/ou la livraison suite au premier confinement de 2020.',
    author: 'Xavier Rain',
    id: 'web-gm-1',
    cover: gmLetsgaultCover,
    gallery: [gmLetsgault1, gmLetsgault2],
    thumb: [gmLetsgaultThumb],
    company: 'Gault&Millau',
    year: '2020',
    logo: gaultmillau,
    logoWhite: gaultmillauWhite,
    layerColor: '#000000',
    techno: ['WIX', 'JS', 'HTML', 'CSS', 'Photoshop', 'Illustrator'],
    link: 'https://letsgault.fr'
  },
  {
    position: 7,
    name: 'Mailings',
    category: 'web',
    description: 'Divers mailings',
    author: 'Xavier Rain',
    id: 'web-gm-2',
    cover: gmMailingCover,
    gallery: [gmMailing1, gmMailing2],
    thumb: [gmMailingThumb],
    company: 'Gault&Millau',
    year: '2020',
    logo: gaultmillau,
    logoWhite: gaultmillauWhite,
    layerColor: '#83d356',
    techno: ['HTML', 'CSS', 'Mailjet'],
    link: null
  },
  {
    position: 8,
    name: 'Page Champagne',
    category: 'web',
    description:
      'Page dédiée à la sélection de champagnes dégustés par Gault&Millau',
    author: 'Xavier Rain',
    id: 'web-gm-3',
    cover: gmChampagneCover,
    gallery: [gmChampagne],
    thumb: [gmChampagneThumb],
    company: 'Gault&Millau',
    year: '2019',
    logo: gaultmillau,
    logoWhite: gaultmillauWhite,
    layerColor: '#edf47f',
    techno: ['HTML', 'CSS', 'Rails', 'Angular', 'GIT'],
    link: 'https://fr.gaultmillau.com/champagne'
  },
  {
    position: 9,
    name: 'Maquettes du nouveau site',
    category: 'web',
    description: 'Maquettes nouveau site Gault&Millau',
    author: 'Xavier Rain',
    id: 'web-gm-4',
    cover: gmRedesignCover,
    gallery: [gmRedesign1, gmRedesign2],
    thumb: [gmRedesignThumb],
    company: 'Gault&Millau',
    year: '2020',
    logo: gaultmillau,
    logoWhite: gaultmillauWhite,
    layerColor: '#ff5f78',
    techno: ['Photoshop', 'Illustrator', 'Indesign'],
    link: 'https://fr.gaultmillau.com/'
  },
  {
    position: 10,
    name: 'Nouvelle boutique',
    category: 'web',
    description:
      'Refonte de la boutique existante vendant les plaques et livres de Gault&Millau auprès des restaurateurs et artisans.',
    author: 'Xavier Rain',
    id: 'web-gm-5',
    cover: gmShopCover,
    gallery: [gmShop1, gmShop2, gmShop3, gmShop3, gmShop4, gmShop5],
    thumb: [gmShopThumb],
    company: 'Gault&Millau',
    year: '2020',
    logo: gaultmillau,
    logoWhite: gaultmillauWhite,
    layerColor: '#258eb1',
    techno: ['HTML', 'CSS', 'JS', 'Shopify', 'Photoshop', 'Illustrator'],
    link: 'https://boutique.gaultmillau.fr'
  },
  {
    position: 11,
    name: 'Site vitrine Nicolas Besson',
    category: 'web',
    description: 'Création du nouveau site pour Nicolas Besson Couturier',
    author: 'Xavier Rain',
    id: 'web-nicolasbesson-1',
    cover: nicolasBessonSiteCover,
    gallery: [
      nicolasBessonSite1,
      nicolasBessonSite2,
      nicolasBessonSite3,
      nicolasBessonSite4
    ],
    thumb: [nicolasBessonSiteThumb],
    company: 'Nicolas Besson',
    year: '2020',
    logo: nicolasbesson,
    logoWhite: null,
    layerColor: '#E7E3EB',
    techno: [
      'HTML',
      'CSS',
      'JS',
      'PHP',
      'Wordpress',
      'Photoshop',
      'Illustrator'
    ],
    link: null
  },
  {
    position: 12,
    name: 'Site GCBC UCPA',
    category: 'web',
    description:
      'Création du nouveau site pour le Groupement des Chefs de Bord de Croisière UCPA',
    author: 'Xavier Rain',
    id: 'web-gcbc-1',
    cover: gcbcSiteCover,
    gallery: [gcbcSite1, gcbcSite2, gcbcSite3, gcbcSite4, gcbcSite5, gcbcSite6],
    thumb: [gcbcSiteThumb],
    company: 'GCBC UCPA',
    year: '2022',
    logo: gcbc,
    logoWhite: null,
    layerColor: '#38B4B7',
    techno: [
      'HTML',
      'CSS',
      'JS',
      'PHP',
      'Wordpress',
      'Photoshop',
      'Illustrator'
    ],
    link: 'https://gcbcucpa.com'
  }
]
