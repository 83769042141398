export const socials = [
  {
    name: 'twitter',
    display: false,
    url: 'https://www.google.com'
  },
  {
    name: 'linkedin',
    display: true,
    url: 'https://www.linkedin.com/in/xavierrain/'
  },
  {
    name: 'dribbble',
    display: false,
    url: 'https://www.google.com'
  },
  {
    name: 'pinterest',
    display: true,
    url: 'https://pin.it/7tNyYeK'
  },
  {
    name: 'facebook',
    display: false,
    url: 'https://www.google.com'
  },
  {
    name: 'instagram',
    display: false,
    url: 'https://www.google.com'
  },
  {
    name: 'reddit',
    display: false,
    url: 'https://www.google.com'
  },
  {
    name: 'youtube',
    display: false,
    url: 'https://www.google.com'
  },
  {
    name: 'behance',
    display: false,
    url: 'https://www.google.com'
  },
  {
    name: 'skype',
    display: true,
    url: 'https://join.skype.com/invite/OkEFeIzTOaXp'
  },
  {
    name: 'camera-video',
    display: true,
    url: 'https://us04web.zoom.us/j/5999007159?pwd=RGFyNExRYVpadlAzYzZJdVF5VDdUUT09'
  },
  {
    name: 'apple',
    display: false,
    url: 'https://www.google.com'
  }
]
