// REACT

import React, { useRef, useEffect, useReducer } from 'react'

// REACT ROUTER DOM

import { Link } from 'react-router-dom'

// HOOKS

import { useTheme } from '../../utils/hooks'

// Google reCAPTCHA

import Recaptcha from '../../security/recaptcha'

// EMAIL SERVICE

import emailjs from '@emailjs/browser'

// REACT ROUTER

import { useSearchParams } from 'react-router-dom'

// QUESTIONS LIST

import { questionFormList, questionTitle } from '../../assets/datas/contactForm'

// MY COMPONENTS

import ContactMe from '../contact'

//FONTAWESOME

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// BOOTSTRAP

import {
  Form,
  Row,
  Col,
  Alert,
  ToggleButton,
  FloatingLabel,
  Button,
  ProgressBar,
  Image
} from 'react-bootstrap'

// IMAGES

import information from '../../assets/img/contact-form/information.svg'
import site from '../../assets/img/contact-form/site.svg'
import goal from '../../assets/img/contact-form/goal.svg'
import tree from '../../assets/img/contact-form/tree.svg'
import users from '../../assets/img/contact-form/users.svg'
import end from '../../assets/img/contact-form/finished.svg'

function CustomForm() {
  const { theme } = useTheme()
  const [searchParams, setSearchParams] = useSearchParams()

  const paramOffer = searchParams.get('_offerPosition')

  const form = useRef()
  const checkedButtonServices = useRef([])
  const checkedButtonOffers = useRef([])

  // GET LOCAL STORAGE  // GLOBAL FORM STATES

  const localFormSaved = localStorage.getItem('localFormSaved')

  ///////////////////////////////////////////////////////////////

  // USE REDUCER

  const formReducer = (state, action) => {
    switch (action.type) {
      case 'navigate': {
        return {
          ...state,
          step: action.step
        }
      }
      case 'firstStep': {
        return {
          ...state,
          step: 1
        }
      }
      case 'siteExist': {
        return {
          ...state,
          siteExist: action.bool
        }
      }
      case 'checkedService': {
        return {
          ...state,
          checkedService: action.arr
        }
      }
      case 'checkedOffer': {
        return {
          ...state,
          checkedOffer: action.arr
        }
      }
      case 'credentialsAlert': {
        return {
          ...state,
          credentialsAlert: action.bool
        }
      }
      case 'otherDemand': {
        return {
          ...state,
          otherDemand: action.bool
        }
      }
      case 'previous': {
        return {
          ...state,
          step: --state.step
        }
      }
      case 'next': {
        return {
          ...state,
          step: ++state.step
        }
      }
      case 'updateLocalForm': {
        return {
          ...state,
          localForm: action.form
        }
      }
      case 'loading': {
        return {
          ...state,
          loading: true
        }
      }
      case 'finished': {
        return {
          ...state,
          finished: true
        }
      }
      case 'submitted': {
        return {
          ...state,
          submitted: action.bool
        }
      }
      case 'validated': {
        return {
          ...state,
          validated: action.bool
        }
      }
      case 'success': {
        return {
          ...state,
          loading: false,
          sent: true
        }
      }
      case 'error': {
        return {
          ...state,
          errorMsg: true,
          error: true,
          loading: false
        }
      }
      case 'reset': {
        return {
          ...state,
          error: false,
          step: 1,
          finished: false,
          submitted: null,
          validated: null,
          sent: false,
          checkedService: [false, false, false, false, false, false],
          otherDemand: false,
          localForm: {}
        }
      }
      case 'tryAgain': {
        return {
          ...state,
          error: false,
          validated: false
        }
      }
      default:
        return state
    }
  }

  const initialState = {
    localForm: localFormSaved ? JSON.parse(localFormSaved) : {},
    step: 1,
    siteExist: false,
    otherDemand: false,
    credentialsAlert: null,
    checkedService: [false, false, false, false, false, false],
    checkedOffer: [false, false, false],
    finished: false,
    error: false,
    errorMsg: null,
    loading: false,
    submitted: null,
    validated: null,
    sent: false
  }

  const [state, dispatch] = useReducer(formReducer, initialState)

  const {
    localForm,
    step,
    siteExist,
    otherDemand,
    credentialsAlert,
    checkedService,
    checkedOffer,
    finished,
    error,
    errorMsg,
    loading,
    submitted,
    validated,
    sent
  } = state

  ///////////////////////////////////////////////////////////////////

  // PUT BACK INPUT VALUES IF RELOAD PAGE AND DISPLAY OFFER SELECTED IF THERE IS ONE

  useEffect(() => {
    fillFormLocalStorage()
  }, [])

  useEffect(() => {
    if (validated === null) return
    if (validated) {
      window.scrollTo(0, 0)
      dispatch({ type: 'loading' })
      const contacting = setTimeout(() => {
        emailjs
          .sendForm(
            'service_r4uuui6',
            'template_w694u3o',
            form.current,
            'bHbiJteO-LHbrQPlJ'
          )
          .then(
            (result) => {
              dispatch({ type: 'success' })
              console.log(result)
            },
            (error) => {
              dispatch({ type: 'error' })
              console.log(error)
            }
          )
      }, 2000)
      return () => clearTimeout(contacting)
    } else if (step !== 1) {
      dispatch({ type: 'firstStep' })
    } else {
      window.scrollTo(0, 0)
    }
  }, [validated])

  // SCROLL
  useEffect(() => {
    fillFormLocalStorage()
    window.scrollTo(0, 0)
  }, [step])

  useEffect(() => {
    if (paramOffer) {
      const paramConverted = parseInt(paramOffer)
      checkedButton(
        checkedOffer,
        'checkedOffer',
        checkedButtonOffers,
        paramConverted,
        'offers'
      )
    }
  }, [searchParams])

  useEffect(() => {
    fillFormLocalStorage()
    // LOCAL STORAGE FORM IF CHANGES
    const localFormSet = localStorage.setItem(
      'localFormSaved',
      JSON.stringify(localForm)
    )
  }, [localForm])

  const checkedButton = (state, setState, ref, position, block, e) => {
    if (block === 'services') {
      ref.current = state.map((item, index) =>
        index === position ? !item : item
      )
      ref.current.at(-1) === true
        ? dispatch({ type: 'otherDemand', bool: true })
        : dispatch({ type: 'otherDemand', bool: false })
    } else if (block === 'offers') {
      ref.current = state.map((item, index) =>
        index === position ? !item : false
      )
    }
    dispatch({ type: setState, arr: ref.current })
  }

  // WHAT HAPPENS WHEN USER CHANGES INPUTS

  const handleFormChanges = (action) => {
    let localFormObjUpdated = {}
    const inputs = document.getElementsByTagName('input')
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].id) {
        if (inputs[i].type === 'checkbox') {
          localFormObjUpdated = {
            ...localFormObjUpdated,
            [inputs[i].id]: inputs[i].checked
          }
        } else if (inputs[i].type === 'radio') {
          localFormObjUpdated = {
            ...localFormObjUpdated,
            [inputs[i].id]: {
              checked: inputs[i].checked,
              value: inputs[i].value
            }
          }
        } else {
          localFormObjUpdated = {
            ...localFormObjUpdated,
            [inputs[i].id]: inputs[i].value
          }
        }
        dispatch({
          type: 'updateLocalForm',
          form: { ...localForm, ...localFormObjUpdated }
        })
      }
    }
    dispatch({ type: action })
  }

  // SEND EMAIL FUNCTION

  const sendEmail = (event) => {
    event.preventDefault()
    event.stopPropagation()
    dispatch({ type: 'submitted', bool: true })
    if (event.currentTarget.checkValidity() === false) {
      dispatch({ type: 'validated', bool: false })
    } else {
      dispatch({ type: 'validated', bool: true })
    }
    event.preventDefault()
  }

  const isRadioSiteChecked = (e) => {
    const siteNo = document.getElementById('inputSiteExistNo')
    const siteYes = document.getElementById('inputSiteExistYes')
    const credentialsNo = document.getElementById('inputCredentialsYes')
    const credentialsYes = document.getElementById('inputCredentialsNo')
    switch (e.target.id) {
      case 'inputSiteExistYes':
        siteNo.checked = false
        siteNo.value = 'off'
        siteYes.checked = true
        siteYes.value = 'on'
        break
      case 'inputSiteExistNo':
        siteYes.checked = false
        siteYes.value = 'off'
        siteNo.checked = true
        siteNo.value = 'on'
        break
      case 'inputCredentialsNo':
        credentialsNo.checked = false
        credentialsNo.value = 'off'
        credentialsYes.checked = true
        credentialsYes.value = 'on'
        break
      case 'inputCredentialsYes':
        credentialsYes.checked = false
        credentialsYes.value = 'off'
        credentialsNo.checked = true
        credentialsNo.value = 'on'
        break
      default:
        return
    }
  }

  // WHAT HAPPENS WHEN CLICK ON RADIO INPUTS

  const updateQuestionsStates = (e, func, setTo, relatedTo, alert) => {
    switch (func) {
      case 'updateSiteExist':
        dispatch({ type: 'siteExist', bool: setTo })
        fillFormLocalStorage(e)
        break
      case 'updateCredentialsAlert':
        dispatch({ type: 'credentialsAlert', bool: setTo })
        break
      default:
        return null
    }
  }

  // THIS FUNCTION FILL FORM IF SOMETHING HAS ALREADY BEEN WRITTEN IN IT

  const fillFormLocalStorage = (e) => {
    const localFormFilled = Object.keys(localForm).length !== 0
    if (localFormFilled) {
      const arrKeysLocalForm = Object.keys(localForm)
      for (let i = 0; i < arrKeysLocalForm.length; i++) {
        let el = document.getElementById(arrKeysLocalForm[i])
        if (el && localForm[arrKeysLocalForm[i]]) {
          if (el.type === 'checkbox') {
            el.checked = localForm[arrKeysLocalForm[i]]
          } else if (el.type === 'radio') {
            el.checked = localForm[arrKeysLocalForm[i]].checked
            el.value = localForm[arrKeysLocalForm[i]].value
          } else {
            el.value = localForm[arrKeysLocalForm[i]]
          }
        }
      }
    } else {
      const inputs = document.querySelectorAll(
        '.forms-deegity input, .forms-deegity textarea'
      )
      for (let j = 0; j < inputs.length; j++) {
        let input = inputs[j]
        if (input.type === 'checkbox') {
          input.checked = false
        } else if (input.type === 'radio') {
          if (
            input.id === 'inputSiteExistNo' ||
            input.id === 'inputCredentialsNo'
          ) {
            input.checked = true
            input.value = 'on'
          } else {
            input.checked = false
            input.value = 'off'
          }
        } else input.value = ''
      }
    }
  }

  return (
    <>
      <Row className={`overflow-hidden ${validated ? 'd-none' : ''}`}>
        <Col>
          <Form
            noValidate
            validated={submitted}
            ref={form}
            onSubmit={sendEmail}
            className="form-contact forms-deegity"
          >
            <Row className="row-info">
              <Col lg={12} className="animate__animated animate__fadeIn">
                {questionTitle.map(
                  (question, index) =>
                    step === question.step && (
                      <header key={index}>
                        <h1 className="mt-5 huge-text-4 text-colored-secondary-letter mb-4">
                          {question.title}
                        </h1>
                        <p className="fw-light mb-5 fs-4">
                          {question.subtitle}
                        </p>
                      </header>
                    )
                )}
              </Col>
            </Row>
            <Row
              className={`row-content rounded-3 mt-3 gx-1 p-4 ${
                theme === 'light'
                  ? 'bg-neutral shadow-dgt'
                  : 'shadow-dgt-light '
              }`}
            >
              <Col xs={12}>
                <Row>
                  <Col lg={3} className="d-none d-lg-flex">
                    <section className="justify-content-between d-flex steps-progress position-relative animate__animated animate__fadeInLeft animate__delay-1s">
                      <ProgressBar
                        animated
                        now={100}
                        className="position-absolute"
                      />
                      <ul className="mt-3 d-flex d-flex flex-column list-unstyled justify-content-between position-relative">
                        {questionTitle.map((item, index) => (
                          <li
                            key={`steps-${index}`}
                            onClick={() =>
                              dispatch({ type: 'navigate', step: item.step })
                            }
                            className={`col d-flex flex-row align-items-center justify-content-between`}
                          >
                            <h5
                              className={`text-left mt-3 me-3 fix-width-timeline ${
                                item.step > step && 'fw-light'
                              } ${
                                item.step === step
                                  ? 'text-colored-secondary-letter'
                                  : ''
                              }`}
                            >
                              {item.titleShort}
                            </h5>
                            <div
                              className={`rounded-steps rounded-circle d-flex align-items-center justify-content-center ${
                                item.step === step
                                  ? 'bg-secondary-dgt'
                                  : 'bg-neutral'
                              } ${item.step <= step && 'active'}`}
                            >
                              <FontAwesomeIcon
                                icon={item.icon}
                                size="1x"
                                className={`fs-4 svg-medium-dark`}
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </section>
                  </Col>

                  <Col xs={12} lg={{ span: 8, offset: 1 }}>
                    <Row>
                      {submitted === true && validated === false ? (
                        <Col key="error-form" xs={12}>
                          {step === 1 ? (
                            // ALERT WHEN SUBMITTED BUT FORM NOT VALIDATED

                            <Alert
                              variant="warning"
                              className={`mt-3 ${
                                theme === 'dark' ? 'text-dark' : ''
                              }`}
                            >
                              Vos coordonnées n'ont pas été saisies
                              correctement.
                            </Alert>
                          ) : (
                            <Alert variant="warning" className="mt-3">
                              Vos coordonnées n'ont pas été saisies
                              correctement.
                              <Alert.Link
                                // onClick={() => updateStep(1)}
                                onClick={() => dispatch({ type: 'firstStep' })}
                              >
                                Je corrige
                              </Alert.Link>
                            </Alert>
                          )}
                        </Col>
                      ) : null}

                      {step > 1 && (
                        <div>
                          <Alert
                            variant="warning"
                            className={`mt-3 d-none d-lg-block ${
                              theme === 'light' ? '' : 'text-secondary'
                            }`}
                          >
                            Dans cette section, aucune réponse n'est obligatoire
                            {step !== 6 && (
                              <span
                                className={`${
                                  theme === 'light' ? '' : 'text-secondary'
                                }`}
                              >
                                , vous pouvez aussi sauter des étapes en
                                cliquant sur les éléments à votre gauche 😉
                              </span>
                            )}
                          </Alert>

                          <Alert
                            variant="warning"
                            className={`mt-3 d-lg-none ${
                              theme === 'light' ? '' : 'text-secondary'
                            }`}
                          >
                            Dans cette section, aucune réponse n'est obligatoire
                            😉
                            {step !== 6 && (
                              <div>
                                <span
                                  className={`${
                                    theme === 'light' ? '' : 'text-secondary'
                                  }`}
                                >
                                  Pour aller directement à la dernière étape{' '}
                                </span>
                                <Alert.Link
                                  onClick={() =>
                                    dispatch({ type: 'navigate', step: 6 })
                                  }
                                >
                                  cliquez-ici
                                </Alert.Link>
                              </div>
                            )}
                          </Alert>
                        </div>
                      )}

                      {questionFormList.map((question, index) =>
                        (((question.type === 'text' ||
                          question.type === 'email') &&
                          (!question.relatedTo ||
                            (question.relatedTo === 'siteExist' && siteExist) ||
                            (question.relatedTo === 'siteNotExist' &&
                              siteExist === false) ||
                            (question.relatedTo === 'otherDemand' &&
                              otherDemand))) ||
                          question.type === 'number' ||
                          question.type === 'tel') &&
                        question.display ? (
                          // INPUT TEXT

                          <Col
                            xs={question.xscol}
                            md={question.mdcol}
                            key={`col-${question.controlId}`}
                            className={
                              question.step === step
                                ? 'animate__animated animate__fadeIn animate__slow animate__fast'
                                : 'd-none'
                            }
                          >
                            <FloatingLabel
                              className="mt-2 mb-2"
                              controlId={question.controlId}
                              key={index}
                              label={question.content}
                            >
                              <Form.Control
                                size="sm"
                                type={question.type}
                                placeholder={question.placeholder}
                                required={question.required}
                                name={question.name}
                                className="rounded-3"
                              />
                            </FloatingLabel>
                            <Form.Control.Feedback type="invalid">
                              {question.msgInvalid}
                            </Form.Control.Feedback>
                          </Col>
                        ) : //////////////// INPUT RADIO

                        question.type === 'radio' &&
                          (!question.relatedTo ||
                            (question.relatedTo === 'siteExist' && siteExist) ||
                            (question.relatedTo === 'siteNotExist' &&
                              siteExist === false)) &&
                          question.display ? (
                          <Col
                            xs={question.xscol}
                            md={question.mdcol}
                            key={`col-${question.controlId}`}
                            className={
                              question.step === step
                                ? 'animate__animated animate__fadeIn animate__slow animate__fast'
                                : 'd-none'
                            }
                          >
                            <Form.Group
                              className="mt-3 mb-3"
                              controlId={question.controlId}
                              key={index}
                            >
                              <Form.Label className="d-block">
                                {question.content}
                              </Form.Label>
                              {question.inputsRadio.map((input, index) => (
                                <Form.Check
                                  defaultChecked={input.defaultChecked}
                                  className="ps-0"
                                  key={index}
                                  inline
                                  onChange={(e) => isRadioSiteChecked(e)}
                                  label={input.label}
                                  name={input.name}
                                  type={input.type}
                                  id={input.id}
                                  required={input.required}
                                  onClick={(e) =>
                                    updateQuestionsStates(
                                      e,
                                      input.function,
                                      input.setTo,
                                      input.relatedTo,
                                      input.alert
                                    )
                                  }
                                />
                              ))}

                              {credentialsAlert &&
                              question.controlId === 'inputCredentials' ? (
                                <Alert variant="success mt-2">
                                  <p>{question.alert}</p>
                                </Alert>
                              ) : null}
                            </Form.Group>
                          </Col>
                        ) : //////////////// INPUT TEXTAREA

                        question.type === 'textarea' &&
                          (!question.relatedTo ||
                            (question.relatedTo === 'siteExist' && siteExist) ||
                            (question.relatedTo === 'siteNotExist' &&
                              siteExist === false)) &&
                          question.display ? (
                          <Col
                            xs={question.xscol}
                            md={question.mdcol}
                            key={`col-${question.controlId}`}
                            className={
                              question.step === step
                                ? 'animate__animated animate__fadeIn animate__slow animate__fast'
                                : 'd-none'
                            }
                          >
                            <Form.Group
                              className="mb-3 mt-3"
                              controlId={question.controlId}
                              key={index}
                            >
                              <Form.Label>{question.content}</Form.Label>
                              <Form.Control
                                size="sm"
                                as={question.type}
                                rows={question.rows}
                                required={question.required}
                                name={question.name}
                                className="rounded-3"
                              />
                            </Form.Group>
                          </Col>
                        ) : // SERVICES

                        question.type === 'checkbox' &&
                          question.display &&
                          question.typeContent === 'service' ? (
                          <Col
                            xs={question.xscol}
                            md={question.mdcol}
                            key={`col-${question.controlId}`}
                            className={`full-button d-flex  ${
                              question.step === step
                                ? 'animate__animated animate__fadeIn animate__slow animate__fast'
                                : 'd-none'
                            }`}
                          >
                            <ToggleButton
                              className="rounded-3 mb-2 mt-2 d-flex align-items-center flex-column justify-content-around"
                              key={index}
                              id={question.controlId}
                              as="input"
                              type={question.type}
                              variant="outline-primary"
                              checked={checkedService[question.checked]}
                              value={question.content}
                              onClick={() =>
                                checkedButton(
                                  checkedService,
                                  'checkedService',
                                  checkedButtonServices,
                                  question.checked,
                                  'services'
                                )
                              }
                              name={question.name}
                            >
                              <FontAwesomeIcon
                                icon={question.icon}
                                size="2x"
                                className="p-2 svg-secondary"
                              />
                              {question.content}
                            </ToggleButton>
                          </Col>
                        ) : //  OFFERS

                        question.type === 'checkbox' &&
                          question.typeContent === 'offer' &&
                          paramOffer ? (
                          <Col
                            xs={question.xscol}
                            md={question.mdcol}
                            key={`col-${question.controlId}`}
                            className={`rounded-3 full-button d-flex ${
                              question.step === step
                                ? 'animate__animated animate__fadeIn animate__slow animate__fast'
                                : 'd-none'
                            }`}
                          >
                            <ToggleButton
                              className="rounded-3 mb-2 mt-2 d-flex align-items-center flex-column justify-content-around"
                              key={index}
                              id={question.controlId}
                              as="input"
                              type={question.type}
                              variant="outline-primary"
                              checked={checkedOffer[question.checked]}
                              value={question.content}
                              onClick={() =>
                                checkedButton(
                                  checkedOffer,
                                  'checkedOffer',
                                  checkedButtonOffers,
                                  question.checked,
                                  'offers'
                                )
                              }
                              name={question.name}
                            >
                              <FontAwesomeIcon
                                icon={question.icon}
                                size="2x"
                                beat={question.controlId === 'inputOffer2'}
                                className={`p-2 svg-secondary`}
                                transform={{
                                  rotate: question.transform
                                }}
                              />
                              {question.content}
                            </ToggleButton>
                          </Col>
                        ) : (question.type === 'single' &&
                            question.controlId === 'contactDetails') ||
                          (question.type === 'single' &&
                            question.controlId === 'whichServices') ||
                          (question.type === 'single' &&
                            question.controlId === 'whichOffer' &&
                            paramOffer) ? (
                          <Col
                            xs={question.xscol}
                            key={`col-${question.controlId}`}
                            className={question.step !== step && 'd-none'}
                          >
                            <h4
                              className={`text-start mb-5 text-colored-secondary-letter ${
                                question.name === 'user_details'
                                  ? 'mt-3'
                                  : 'pt-5 mt-3'
                              }`}
                            >
                              {question.content}
                            </h4>
                          </Col>
                        ) : null
                      )}
                      <Col>
                        {((step === 1 && finished) || step === 6) && (
                          <Row
                            key="recaptcha"
                            className="justify-content-center mt-5"
                          >
                            <Col xs={12}>
                              <Recaptcha />
                            </Col>
                          </Row>
                        )}
                        <Row
                          key="prev-next"
                          className="justify-content-center mt-3"
                        >
                          <Col
                            key="next/prev"
                            xs={12}
                            className="d-flex ml-2 mt-5 justify-content-center flex-column flex-md-row"
                          >
                            {step === 1 && !finished && (
                              <button
                                type="button"
                                onClick={() => handleFormChanges('finished')}
                                className={`m-2 dgt-btn dgt-cta ${
                                  finished ? 'd-none' : ''
                                }`}
                                size="lg"
                              >
                                J'ai terminé !
                              </button>
                            )}

                            <button
                              type="button"
                              onClick={() => handleFormChanges('previous')}
                              className={`m-2 dgt-btn dgt-cta ${
                                step === 1 ? 'd-none' : ''
                              }`}
                              size="lg"
                            >
                              <FontAwesomeIcon
                                icon={['fas', 'angles-left']}
                                className="me-2 svg-dark"
                              />
                              Précédent
                            </button>

                            <button
                              type="button"
                              onClick={() => handleFormChanges('next')}
                              disabled={step === 6}
                              className={`m-2 dgt-btn ${
                                step === 6
                                  ? 'd-none'
                                  : step === 1
                                  ? 'dgt-light'
                                  : 'dgt-cta'
                              }`}
                              size="lg"
                            >
                              {step === 1 ? 'Je précise mon projet' : 'Suivant'}
                              <FontAwesomeIcon
                                icon={['fas', 'angles-right']}
                                className={`ms-2 svg-dark`}
                              />
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      {loading && (
        <Row className="validated-container animate__animated animate__fadeIn animate__slow animate__fast d-flex justify-content-center align-items-center">
          <span className="loader-dgt"></span>
        </Row>
      )}
      {sent ? (
        <Row className="validated-container">
          <Col
            xs="12"
            className="mt-5 d-flex flex-column align-items-center align-content-center justify-space-between"
          >
            <span className="emoji big-emoji text-center">🥳</span>
            <p className="text-center mt-3">
              Merci ! Votre demande a bien été envoyée !<br /> Nous vous
              contacterons très prochainement.
            </p>
            <div className="mt-4 d-flex d-md-block align-items-center align-items-md-start flex-column flex-md-row">
              <Button
                type="button"
                onClick={() => dispatch({ type: 'reset' })}
                className="dgt-btn dgt-cta me-0 me-md-3 mb-4 mb-md-0"
                size="lg"
              >
                J'ai une autre demande
              </Button>
              <Link to="/" className="dgt-btn dgt-light d-inline-flex">
                Retourner à l'accueil
              </Link>
            </div>
          </Col>
        </Row>
      ) : error ? (
        <Row className="validated-container">
          <Col xs="12">
            <Row className="mt-5 d-flex flex-column align-items-center align-content-center justify-space-between">
              <span className="emoji big-emoji text-center">😢</span>
              <p className="text-center mt-3">
                Une erreur s'est produite et j'en suis confus.
                <br />
                Merci de nous contacter à cette adresse
              </p>
              <div className="text-center">
                <ContactMe
                  subject="Prise de contact"
                  body="PS : Il y a eu une erreur dans le formulaire !"
                />
              </div>
              <div className="mt-4 text-center">
                <Button
                  type="button"
                  onClick={() => dispatch({ type: 'tryAgain' })}
                  className="dgt-btn dgt-cta me-3"
                >
                  Je réessaye
                </Button>
                <Link to="/" className="dgt-btn dgt-light d-inline-flex">
                  Retourner à l'accueil
                </Link>
              </div>
            </Row>
          </Col>
        </Row>
      ) : null}
      <Row
        className={`d-none ${
          theme === 'light' ? 'd-lg-flex' : 'd-xxl-flex'
        } position-absolute illustrations-form`}
      >
        <Image
          className="animate__animated animate__fadeInLeft animate__delay-2s"
          src={
            step === 1
              ? information
              : step === 2
              ? site
              : step === 3
              ? goal
              : step === 4
              ? tree
              : step === 5
              ? users
              : end
          }
        />
      </Row>
    </>
  )
}

export default CustomForm
