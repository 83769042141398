// REACT

import React, { useEffect } from 'react'

// META TAGS

import { Helmet } from 'react-helmet-async'

// REACT ROUTER

import { useLocation } from 'react-router-dom'

// HOOKS

import { useAnimAppear, useTheme } from '../../utils/hooks'

// COMPONENTS

import CustomForm from '../../components/customForm'
import Maps from '../../components/maps'

// REACT BOOTSTRAP

import { Col, Container, Row } from 'react-bootstrap'

// IMAGES

import contactThumb from '../../assets/img/contact-form/contact-thumb.jpg'

function ContactPage() {
  const { theme } = useTheme()
  let location = useLocation()

  const { setParamsAnim, setCountAnimation } = useAnimAppear()

  useEffect(() => {
    // Because of anim context provider, this below is mandatory for preventing errors from hook
    setParamsAnim([[[[], '', 0, '']], location.pathname])
    setCountAnimation(true)
  }, [])

  return (
    <>
      <Helmet>
        <title>Contactez-nous</title>
        <meta property="og:title" content="Contactez-nous" />
        <meta
          property="og:description"
          content="Vous avez un projet, une envie ? Remplissez notre formulaire, nous vous contacterons très prochainement !"
        />
        <meta
          name="description"
          content="Vous avez un projet, une envie ? Remplissez notre formulaire, nous vous contacterons très prochainement !"
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta
          property="og:image:secure_url"
          content={`https://www.deegity.com${contactThumb}`}
        />
        <meta property="og:url" content={`https://www.deegity.com/contact`} />
      </Helmet>
      <Container
        fluid
        id="contact"
        className={`position-relative pt-5 overflow-hidden px-0`}
      >
        <Container className="px-0 px-sm-4">
          <Row className="justify-content-center">
            <Col
              xs={10}
              sm={12}
              lg={{ span: 10, offset: 0 }}
              className="position-relative px-0 px-sm-3"
            >
              <CustomForm className="forms-deegity" />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 8, offset: 2 }} className="text-center">
              <header>
                <h1
                  id="title-offers"
                  className={`title-anim huge-text-4 mb-2 text-colored-secondary-letter 
                 `}
                >
                  On se rencontre ?
                </h1>
                <p className="fw-light opacity-100 mb-5 fs-4">
                  Nous sommes ici 👇
                </p>
              </header>
            </Col>
          </Row>
        </Container>
        <div
          className={`contact-pattern-fix h-100 w-100 top-0 end-0 position-absolute ${
            theme === 'light' ? 'bg-lightGray-no-border-neutral' : ''
          }`}
        ></div>
      </Container>
      <Maps></Maps>
    </>
  )
}
export default ContactPage
