// REACT COMPONENTS

import React, { useEffect } from 'react'

// META TAGS

import { Helmet } from 'react-helmet-async'

// REACT ROUTER

import { useLocation } from 'react-router-dom'

// HOOKS

import { useAnimAppear, useTheme } from '../../utils/hooks'

// BOOTSTRAP COMPONENTS

import { Container, Row, Col } from 'react-bootstrap'

// MY COMPONENTS

import Offers from '../../components/offers'
import SharedOffers from '../../components/offers/sharedOffers'

// IMAGES

import deegityThumb from '../../assets/img/logos/deegity/deegity-thumb.jpg'

function OffersPage() {
  const { theme } = useTheme()
  let location = useLocation()

  const { setParamsAnim, setCountAnimation } = useAnimAppear()

  useEffect(() => {
    // Because of anim context provider, this below is mandatory for preventing errors from hook
    setParamsAnim([[[[], '', 0, '']], location.pathname])
    setCountAnimation(true)
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Les offres de Deegity</title>
        <meta property="og:title" content="Les offres de Deegity" />
        <meta
          property="og:description"
          content="Découvrez nos offres adaptées à votre demande et votre entreprise"
        />
        <meta
          name="description"
          content="Découvrez nos offres adaptées à votre demande et votre entreprise"
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta
          property="og:image:secure_url"
          content={`https://www.deegity.com${deegityThumb}`}
        />
        <meta property="og:url" content={`https://www.deegity.com/offres`} />
      </Helmet>
      <Container
        fluid
        className={`pb-5 px-2 px-md-5 ${
          theme === 'light' ? 'bg-lightGray-no-top' : 'bg-pattern-dark-mode'
        }`}
      >
        <Container className="mb-5">
          <Row>
            <Col className="align-items-center d-flex flex-column animate__animated animate__flipInX">
              <h1 className="title-anim text-center huge-text-4 text-colored-secondary-letter mb-3">
                Prêt à démarrer avec Deegity ?
              </h1>
            </Col>
          </Row>
          <SharedOffers />
          <Row className="gx-5 mt-0 mt-lg-4">
            <Offers />
          </Row>
        </Container>
      </Container>
    </>
  )
}
export default OffersPage
