// REACT COMPONENTS

import React from 'react'

//REACT BOOTSTRAP

import { Row, Col, Image } from 'react-bootstrap'

// UTILS HOOKS

import { useTheme } from '../../../utils/hooks'

//FONTAWESOME

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Recommendation({
  logo,
  comment,
  contributor,
  job,
  picture,
  id,
  company,
  index,
  realisationLinkedName
}) {
  const { theme } = useTheme()
  return (
    <>
      <Row>
        <Col>
          <Row className="gx-4 justify-content-center align-items-center">
            <Col xs={6} md={3} className="mb-3">
              <Image
                src={picture}
                roundedCircle
                thumbnail
                fluid
                className="border-dgt-2-secondary"
              />
            </Col>
            <Col
              xs={12}
              md={9}
              className="d-flex justify-content-around flex-column"
            >
              <Row className="align-items-center">
                <Col>
                  <FontAwesomeIcon
                    icon="quote-left"
                    size="5x"
                    className="fa-pull-left svg-secondary"
                  />

                  <blockquote className="blockquote d-inline fs-6">
                    <p
                      className={`fw-light ${
                        theme === 'dark' ? 'text-white' : ''
                      }`}
                    >
                      {comment}
                    </p>
                  </blockquote>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs={12}>
                  <figcaption
                    className={` blockquote-footer ${
                      theme === 'light' ? '' : 'text-white opacity-50'
                    }`}
                  >
                    {contributor}&nbsp;-&nbsp;
                    <cite title="Source Title">{job}</cite>
                    <span className="d-block">{company}</span>
                  </figcaption>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default Recommendation
