// REACT

import React from 'react'

// REACT ROUTER

import { Link } from 'react-router-dom'

// HOOKS

import { useTheme } from '../../utils/hooks'

// REACT BOOTSTRAP

import { Row, Col, Container, Form } from 'react-bootstrap'

//MY COMPONENTS

import ContactMe from '../contact'
import ItemsMenu from '../header/menu/itemsMenu'
import Logo from '../header/logo'

//  SOCIAL NETWORKS

import SocialNetWorks from '../socialNetworks'

function Footer() {
  const windowHeight = window.innerHeight
  const fixHeight = windowHeight > 900
  const { theme, toggleTheme } = useTheme()
  let currentYear = new Date().getFullYear()

  return (
    <footer
      className={`py-5 ${theme === 'light' ? 'bg-primary-dgt' : ''} ${
        fixHeight === true ? 'footer-height-fix' : ''
      }`}
    >
      <Container fluid>
        <Container>
          <Row className="d-flex align-items-center justify-content-between">
            <Col lg={3} className="d-none d-lg-flex flex-column">
              <Row>
                <Col xs={'auto'}>
                  <Logo />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    <small className={`fw-light d-block lh-sm`}>
                      Création de sites web
                      <br />
                      et de supports print
                    </small>
                  </p>
                </Col>
              </Row>
            </Col>

            <Col
              xs={12}
              lg={6}
              className="d-flex flex-column align-items-center align-items-lg-start align-items-lg-center"
            >
              <div className="d-none d-sm-flex justify-content-center flex-wrap text-white footer-nav mb-2">
                <ItemsMenu />
              </div>
              <ul className="list-unstyled d-flex flex-wrap mb-0 justify-content-center">
                <SocialNetWorks />
              </ul>
              <p className="text-center text-sm-end mb-0 mt-3">
                <small className="fw-light">
                  Copyright Deegity {currentYear}. Tous droits réservés.
                </small>
                <small className="ms-1">
                  <Link to="/credits" className="link-dgt">
                    Crédits
                  </Link>
                </small>
              </p>
            </Col>

            <Col
              xs={12}
              lg={3}
              xl={3}
              className="d-flex mt-4 mt-lg-0 align-items-center align-items-lg-end flex-column justify-content-xl-center"
            >
              <ContactMe subject="Prise de contact" body="" />
              <Form.Switch
                className="my-3 switch-mode d-flex"
                onChange={() => toggleTheme()}
                id="theme"
                checked={theme === 'dark' ? true : false}
                label={theme === 'light' ? '🌙 Mode nuit' : '🌞 Mode jour'}
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </footer>
  )
}

export default Footer
