export const pros = [
  {
    iconFA: 'people-group',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'une association',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'person',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'indépendant(e)',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'hammer',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'artisan',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'bolt',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'électricien(ne)',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'people-group',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'une entreprise',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'shop',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'commerçant(e)',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'camera-retro',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'photographe',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'user-tie',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'business (wo)man',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'newspaper',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'journaliste',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'clapperboard',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'acteur(ice)',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'futbol',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'sportif',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'piggy-bank',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'trader',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'book',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'libraire',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'building',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'agent immobilier',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'burger',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'restaurateur',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'car',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'garagiste',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'dog',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'vétérinaire',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'champagne-glasses',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'organisateur de soirées',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'couch',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'vendeur de meubles',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'hand-holding-dollar',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'comptable',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'glasses',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'opticien(ne)',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'guitar',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'musicien(ne)',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'horse',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'éleveur',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'house',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'architecte',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'leaf',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'fleuriste',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'lightbulb',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'inventeur',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'pen-nib',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'écrivain',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'person-biking',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'vendeur de vélos',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'person-hiking',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'guide touristique',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'wand-magic-sparkles',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'magicien(ne)',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'wine-bottle',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'vigneron(ne)',
    sentence: '',
    isAJoke: false
  },
  {
    iconFA: 'virus-covid',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'le covid',
    sentence: '',
    isAJoke: true
  },
  {
    iconFA: false,
    otherIcon: '🍑',
    jobRelated: '@$%!!}&',
    sentence: '',
    isAJoke: true
  },
  {
    iconFA: false,
    otherIcon: false,
    iconPath: 'DarkVador',
    jobRelated: 'mon père',
    sentence: '',
    isAJoke: true
  },
  {
    iconFA: 'baby',
    otherIcon: false,
    iconPath: false,
    jobRelated: "éleveur d'enfants",
    sentence: '',
    isAJoke: true
  },
  {
    iconFA: 'dragon',
    otherIcon: false,
    iconPath: false,
    jobRelated: 'Daenerys Targaryen',
    sentence: '',
    isAJoke: true
  }
]

export const wishes = [
  {
    sentence: 'une carte de visite',
    isAJoke: false,
    category: 'print'
  },
  {
    sentence: 'un nouveau site web',
    isAJoke: false,
    category: 'web'
  },
  {
    sentence: 'un site',
    isAJoke: false,
    category: 'web'
  },
  {
    sentence: 'un site responsive',
    isAJoke: false,
    category: 'web'
  },
  {
    sentence: 'mieux référencer votre site',
    isAJoke: false,
    category: 'web'
  },
  {
    sentence: 'un blog',
    isAJoke: false,
    category: 'web'
  },
  {
    sentence: 'une refonte de votre site',
    isAJoke: false,
    category: 'web'
  },
  {
    sentence: 'un site vitrine',
    isAJoke: false,
    category: 'web'
  },
  {
    sentence: 'un site e-commerce',
    isAJoke: false,
    category: 'web'
  },
  {
    sentence: 'développer une fonctionnalité sur votre site',
    isAJoke: false,
    category: 'web'
  },
  {
    sentence: 'corriger un bug sur votre site',
    isAJoke: false,
    category: 'web'
  },
  {
    sentence: 'un nouveau support de communication',
    isAJoke: false,
    category: 'other'
  },
  {
    sentence: 'juste faire un tour 👀',
    isAJoke: true,
    category: 'joke'
  }
]
